import { Form, InputNumber } from "antd";

export interface SaveInfo{
  compress_data: number
}

function Save(props: any) {
  return (
    <>
      <h2>Save</h2>
      <Form>
        <Form.Item label="compress_data">
            <InputNumber
                value={props.info.compress_data}
                onChange={(v) => props.setInfo({ ...props.info, compress_data: v as number })}
            />
        </Form.Item>
      </Form>
    </>
  );
};

export default Save;
