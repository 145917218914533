import {
  Button,
  Upload,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Typography,
  message
} from "antd";
import { UploadOutlined, InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';

import { saveAs } from 'file-saver';
import Papa from 'papaparse';

import { useState } from "react";
import { getBase64, beforeUpload } from "@/utils/images"
import "@/pages/GeneralPage.scss"

import { useRecoilState } from "recoil";
import { maxTouchKeyState } from "./recoil/userAtom";

const { Dragger } = Upload;

export interface TouchUser {
  key: React.Key;
  user_id: string| null;
  first_name: string| null;
  last_name: string| null;
  class_name: string| null;
  department_id: number| null;
  phone_number: string| null;
  encoded_face_image: string|null;

  height: number | null;
  weight: number | null;
  rfid_number: string | null;
  gender: 1 | 2 | null; // 1 : male, 2: female
}

const numberList: string[] = ["user_id", "height", "weight"];
const requiredList: string[] = ["first_name", "class_name", "department_id"];//, "last_name", "phone_number", "encoded_face_image"


const filterOption = (input: string, option?: { key: string; value: number }) => {
  return (option?.key ?? '').toLowerCase().includes(input.toLowerCase());
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "genderCombo" | "dpmtCombo" | "imageUpload";
  record: TouchUser;
  index: number;
  children: React.ReactNode;
}

function TouchRegister(props: any) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<string>("");

  const [maxKey, setMaxKey] = useRecoilState(maxTouchKeyState);
  const [user_id, setUserID] = useState<string | null>();

  // const [maxKey, setMaxKey] = useState<number>(
  //   Math.max(props.touchUsers.map((o:any) => o.key as number))
  // );
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleUserId = (e: any) =>{
    setUserID(e.target.value);
  }

  const handleChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    let url = await getBase64(info.file.originFileObj as RcFile)

    setLoading(false);
    // url = url.replace(/^data:image\/[a-z]+;base64,/, '')

    setImageUrl(url);

    // getBase64(info.file.originFileObj as RcFile, (url) => {
    //   setLoading(false);
    //   url = url.replace(/^data:image\/[a-z]+;base64,/, '')

    //   setImageUrl(url);
    // });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const validatePhoneNumber = (_: any, value: any) => {
    const regExp = /[^0-9]/;
    if (value === null){
      return Promise.resolve();
    }

    if (regExp.test(value)) {
      return Promise.reject(new Error('숫자만 사용할 수 있습니다.'));
    }
    if (value.length > 11){
      return Promise.reject(new Error('11자리를 넘을수 없습니다.'));
    }

    return Promise.resolve();
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" && dataIndex === "user_id" ? (
        <InputNumber
        min={0}
        onBlur = {handleUserId}
        />
      ) :
      inputType === "number" ? (
        <InputNumber min={0} />
      ) : inputType === "text" ? (
        <Input />
      ) : inputType === "genderCombo" ? (
        <Select
          options={[
            {
              value: null,
              label: "",
            },
            {
              value: 1,
              label: "male",
            },
            {
              value: 2,
              label: "female",
            },
          ]}
        />
      ) : inputType === "dpmtCombo" ? (
        <Select
          showSearch
          placeholder="Select a department name"
          optionFilterProp="children"
          // onChange={(e) => props.setDpmtId(e)}
          filterOption={filterOption}
        >
          {props.dpmtNames.map((dpmt: any) => {
            return (
              dpmt.department_main_category_id === 3 ?
                <Select.Option key={dpmt.name} value={dpmt.id}>
                  {dpmt.name}
                </Select.Option> :<></>
            );
          })}
        </Select>
      ) : (
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {/* {imageUrl ? <img src={`data:image/png;base64,${imageUrl}`} style={{ width: '100%' }} /> : uploadButton} */}
          {imageUrl ? <img src={`${imageUrl}`} style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      );
  
    return (
      <td {...restProps}>
        {editing ? 
          dataIndex!=="phone_number"? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: (user_id === "" || user_id === undefined || user_id === null) && requiredList.includes(dataIndex),
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                validator: validatePhoneNumber,
              }
            ]}
          >
            {inputNode}
          </Form.Item>
        )
        : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record: TouchUser) => record.key === editingKey;

  const edit = (record: Partial<TouchUser> & { key: React.Key }) => {
    // setImageUrl(`data:image/png;base64,${record.encoded_face_image}`);
    setImageUrl(record.encoded_face_image as string);
    form.setFieldsValue({ first_name: "qwer", last_name: "", age: "", address: "", ...record });
    setUserID(record.user_id ? record.user_id : null);
    setEditingKey(record.key as string);
  };

  const cancel = () => {
    setEditingKey("");
    setImageUrl(undefined);
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as TouchUser;

      row.encoded_face_image === "" ? row.encoded_face_image = null : row.encoded_face_image = imageUrl as string;
      row.last_name === "" ? row.last_name = null : row.last_name;
      row.first_name === "" ? row.first_name = null : row.first_name;
      row.phone_number === "" ? row.phone_number = null : row.phone_number;
      row.class_name === "" ? row.class_name = null : row.class_name;
      row.rfid_number === "" ? row.rfid_number = null : row.rfid_number;
      setUserID(undefined);
      setImageUrl(undefined);

      const newData = [...props.touchUsers];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        props.setTouchUsers(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        props.setTouchUsers(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = (key: React.Key) => {
    const newData = props.touchUsers.filter((item:any) => item.key !== key);
    props.setTouchUsers(newData);
  };

  const columns = [
    {
      title: "성", //<span className="custom-red-column">성</span> as unknown as string
      dataIndex: "last_name",
      // width: "25%",
      editable: true,
    },
    {
      title: <span className="custom-red-column">이름</span> as unknown as string,
      dataIndex: "first_name",
      // width: "25%",
      editable: true,
    },
    {
      title: <span className="custom-red-column">기관명 (자동으로 id로 변환)</span> as unknown as string,
      dataIndex: "department_id",
      width: "10%",
      editable: true,
    },
    {
      title: "핸드폰번호", //<span className="custom-red-column">핸드폰번호</span> as unknown as string
      dataIndex: "phone_number",
      // width: "40%",
      editable: true,
    },
    {
      title: <span className="custom-red-column">반명</span>  as unknown as string,
      dataIndex: "class_name",
      // width: "40%",
      editable: true,
    },
    {
      title: "얼굴 이미지", //<span className="custom-red-column"> </span>  as unknown as string
      dataIndex: "encoded_face_image",
      width: "10%",
      editable: true,
      render: (text: string, _: any) => {
        // return <img src={`data:image/jpeg;base64,${text}`} alt="" style={{ width: '100%' }} />
        return <img src={`${text}`} alt="" style={{ width: '100%' }} />
      }
    },
    {
      title: "키",
      dataIndex: "height",
      // width: "40%",
      editable: true,
    },
    {
      title: "몸무게",
      dataIndex: "weight",
      // width: "40%",
      editable: true,
    },
    {
      title: "성별 (1: male, 2: female)",
      dataIndex: "gender",
      width: "10%",
      editable: true,
    },
    {
      title: "RFID",
      dataIndex: "rfid_number",
      // width: "40%",
      editable: true,
    },
    {
      title: "누비 유저 id",
      dataIndex: "user_id",
      // width: "25%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: "5%",
      render: (_: any, record: TouchUser) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <span>
            <Typography.Link
              style={{ marginRight: 10 }}
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link
              onClick={() => handleDelete(record.key)}
            >
              Delete
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TouchUser) => ({
        record,
        inputType: numberList.includes(col.dataIndex) ? "number"
          : col.dataIndex === "gender" ? "genderCombo"
          : col.dataIndex === "department_id" ? "dpmtCombo"
          : col.dataIndex === "encoded_face_image" ? "imageUpload"
          : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = () => {
    const newData: TouchUser = {
      key: maxKey,
      user_id: null,
      last_name:"김",
      first_name: "누비",
      department_id: null,
      phone_number: "01000000000",
      class_name:null,
      height: null,
      weight: null,
      rfid_number: null,
      gender: null,
      encoded_face_image:null
    };
    setMaxKey(maxKey + 1);
    props.setTouchUsers([...props.touchUsers, newData]);
  };

  const handleDownload = () => {
    const csvData = [
      ["last_name", "first_name", "department", "phone_number", "class_name", "height", "weight", "gender(male:1, female:2)", "rfid_number", "user_id"]
    ];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'Touch_Register.csv');
  };
  
  const handleCsvUpload = (file: any) => {
    Papa.parse(file, {
      complete: (result: any) => {
        result.data.forEach((obj: any, index:number) => {
          obj["key"] = maxKey+index;
          obj["department_id"] = props.dpmtNames.find((o:any) => o.name === obj["department"])?.id;
          obj["department_id"] = obj["department_id"] === undefined ? null : obj["department_id"];
          obj["gender"] = obj["gender(male:1, female:2)"] === "" ? null : obj["gender(male:1, female:2)"];
          
          obj["phone_number"] = obj["phone_number"] === "" || obj["phone_number"] === undefined ? null : 
                                obj["phone_number"][0] !== "0" ? `0${obj["phone_number"]}` : obj["phone_number"];
          
          obj["phone_number"] = obj["phone_number"] !== null ? obj["phone_number"].replace(/-/g,"") : null;

          obj["user_id"] = obj["user_id"] === ""? null :obj["user_id"];
          obj["weight"] = obj["weight"] === "" ? null : obj["weight"];
          obj["height"] = obj["height"] === "" ? null : obj["height"];

          obj["last_name"] = obj["last_name"] === "" ? null : obj["last_name"];
          obj["first_name"] = obj["first_name"] === "" ? null : obj["first_name"];
          obj["rfid_number"] = obj["rfid_number"] === "" ? null : obj["rfid_number"];
          obj["encoded_face_image"] = null;

          setMaxKey(maxKey+index+1);
        });
        props.setTouchUsers([...props.touchUsers, ...result.data]);
      },
      header: true, // 첫 번째 행을 헤더로 사용
    });
  }

  const deleteAllUser = () => {
    setEditingKey("");
    setImageUrl(undefined);
    props.setTouchUsers([]);
  };

  const dragUploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    showUploadList:false,
    beforeUpload: 
    // (file) => {return beforeUpload(file)},
    (file) => {
      const acceptFormat = ['jpg', 'jpeg', 'png']
      return acceptFormat.includes(file.name.split('.')[1].toLowerCase()) ? false : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      if (props.touchUsers.length <= 0){
        message.error('please register user, before add face image');
        return false
      }
      const filename = info.file.name.normalize('NFC'); //info.file.name 간 => ㄱㅏㄴ 이렇게 깨져서 NFC로 normalize
      let isImageSet = false;
      // console.log(info)

      props.touchUsers.forEach(async (user: TouchUser) => {
        let fullName = user.last_name ? user.last_name : "";
        fullName = user.first_name ? fullName + user.first_name : fullName;

        if (fullName !== "" && filename.includes(fullName)) {
          isImageSet = true;
          let url = await getBase64(info.file as RcFile);
          setLoading(true);
          // user.encoded_face_image = url.replace(/^data:image\/[a-z]+;base64,/, '')
          user.encoded_face_image = url;

          // getBase64(info.file as RcFile, (url) => {
          //   setLoading(true);
          //   user.encoded_face_image = url.replace(/^data:image\/[a-z]+;base64,/, '')
          // });
        }
        else{
          setLoading(true);
          user.encoded_face_image = user.encoded_face_image ? user.encoded_face_image : null;
        }
      });
      // forceUpdate()
      // props.setTouchUsers([...props.touchUsers]);
      isImageSet === false ? message.error(`"${filename}" is not match`):message.success(`"${filename}" upload success`);
      setImageUrl(undefined);
      
      if (info.file.uid === info.fileList[info.fileList.length-1].uid){
        setLoading(false);
      }
      return true;
    },
    
    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
  };

  return (
    <>
      <Form.Item>
        <Button type="primary" onClick={handleDownload} >
          양식 다운로드
        </Button>
        <Upload 
          showUploadList = {false}
          beforeUpload={(file) => {
            if (!"csv".includes(file.name.split('.')[1])) {
              message.error('You can only upload csv file!');
              return Upload.LIST_IGNORE;
            }

            handleCsvUpload(file);
            return false;
        }}>
          <Button icon={<UploadOutlined />}>Kids Touch CSV Upload (UTF-8)</Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 , marginRight:3}}>
          Add a row
        </Button>
        <Button onClick={deleteAllUser} type="primary" style={{ marginBottom: 16 }}>
          DELETE all row
        </Button>
        <Dragger
          // onChange={(e)=>setLoading(false)}
          {...dragUploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or Drag Face Images to this area to upload</p>
        </Dragger>
      </Form.Item>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            }
          }}
          bordered
          dataSource={props.touchUsers}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }} 
        />
      </Form>
    </>
  );
};

export default TouchRegister;
