import DepartmentInfo from "@/components/department/DepartmentInfo";
import DepartmentOperation from "@/components/department/DepartmentOperation";
import DashboardOption from "@/components/department/DashboardOption";
import AdminUser from "@/components/department/AdminUser";
import Address from "@/components/department/Address";
import "./GeneralPage.scss"
import { showModal } from "@/utils/Modal";
// import { makeDatetimeStrToTime } from "@/utils/function";
import { formatTime }  from "@/utils/function";

import { useLocation } from 'react-router-dom';
import axiosInstance from "@/components/common/AxiosInstance";

import { Button } from "antd";

import { useEffect } from "react";
import { useRecoilState } from "recoil";
import {
  mainState,
  subState,
  typeState,
  isOfficeDisabledState,
  isValidNameEngState,
  dpmtNameState,
  dpmtNameEngState,
  mealCountState,
  invalidMealCountState,
  officeState,
  dpmtEstablishmentCategoryIdState,
  dpmtEstablishmentCategoryState,
  serviceTypeIdState,
  startDateState,
  endDateState,
  startBTimeState,
  endBTimeState,
  startLTimeState,
  endLTimeState,
  startDTimeState,
  endDTimeState,
  startNTimeState,
  endNTimeState,
  dashboardOptionState,
  userNameState,
  emailAddressState,
  phoneNumberState,
  countryIdState,
  stateIdState,
  cityIdState,
  districtIdState,
  preStartDateState,
  buttonClickedState,
  roadAddressState,
  buildingAddressState,
  baTypeState
} from "@/components/department/recoil/departmentAtom";

function DepartmentPage() {
  const [dpmtName, setDpmtName] = useRecoilState(dpmtNameState);
  const [dpmtNameEng, setDpmtNameEng] = useRecoilState(dpmtNameEngState);
  const [main, setMain] = useRecoilState(mainState);
  const [sub, setSub] = useRecoilState(subState);
  const [type, ] = useRecoilState(typeState);
  const [isOfficeDisabled, ] = useRecoilState(isOfficeDisabledState);
  const [mealCount, setMealCount] = useRecoilState(mealCountState);
  const [invalidMealCount, ] = useRecoilState(invalidMealCountState);
  const [office, setOffice] = useRecoilState(officeState);
  const [isValidNameEng, ] = useRecoilState(isValidNameEngState);

  const [dpmtEstablishmentCategoryId, ] = useRecoilState(dpmtEstablishmentCategoryIdState);
  const [, setDpmtEstablishmentCategory] = useRecoilState(dpmtEstablishmentCategoryState);
  const [serviceTypeId, ] = useRecoilState(serviceTypeIdState);
  const [preStartDate, setPreStartDate] = useRecoilState(preStartDateState);
  const [startDate, setStartDate] = useRecoilState(startDateState);
  const [endDate, setEndDate] = useRecoilState(endDateState);
  const [startBTime, setStartBTime] = useRecoilState(startBTimeState);
  const [endBTime, setEndBTime] = useRecoilState(endBTimeState);
  const [startLTime, setStartLTime] = useRecoilState(startLTimeState);
  const [endLTime, setEndLTime] = useRecoilState(endLTimeState);
  const [startDTime, setStartDTime] = useRecoilState(startDTimeState);
  const [endDTime, setEndDTime] = useRecoilState(endDTimeState);
  const [startNTime, setStartNTime] = useRecoilState(startNTimeState);
  const [endNTime, setEndNTime] = useRecoilState(endNTimeState);
  const [dashboardOption, ] = useRecoilState(dashboardOptionState);
  const [userName, ] = useRecoilState(userNameState);
  const [emailAddress, ] = useRecoilState(emailAddressState);
  const [phoneNumber, ] = useRecoilState(phoneNumberState);
  const [countryId, ] = useRecoilState(countryIdState);
  const [stateId, ] = useRecoilState(stateIdState);
  const [cityId, ] = useRecoilState(cityIdState);
  const [districtId, ] = useRecoilState(districtIdState);
  const [roadAddress, ] = useRecoilState(roadAddressState);
  const [buildingAddress, ] = useRecoilState(buildingAddressState);
  const [, setButtonClicked] = useRecoilState(buttonClickedState);
  const [baType, ] = useRecoilState(baTypeState);

  const location = useLocation();
  
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // console.log(queryParams);
    
    if (queryParams.size > 0){
      // URLSearchParams를 사용하여 쿼리 파라미터 추출
      const queryDpmtName = queryParams.get('dpmtName'); 
      queryDpmtName? setDpmtName(queryDpmtName) : null;
      const queryDpmtNameEng = queryParams.get('dpmtNameEng'); 
      queryDpmtNameEng? setDpmtNameEng(queryDpmtNameEng) : null;

      if (queryDpmtName?.includes("학교")){
        setMain(2);
        queryDpmtName.includes("초등학교") ? setSub(3):null;
        queryDpmtName.includes("중학교") ? setSub(4):null;
        queryDpmtName.includes("고등학교") ? setSub(5):null;
        queryDpmtName.includes("대학교") ? setSub(6):null;
      }
      if (queryDpmtName?.includes("어린이집") || queryDpmtName?.includes("유치원")){
        setMain(3);
        queryDpmtName.includes("어린이집") ? setSub(7):null;
        queryDpmtName.includes("유치원") ? setSub(8):null;
      }

      const queryEstablishCategory = queryParams.get('establishCategory'); 
      queryEstablishCategory? setDpmtEstablishmentCategory(queryEstablishCategory) : null;

      const queryMealCount = queryParams.get('mealCount'); 
      queryMealCount? setMealCount(Number(queryMealCount)) : null;

      const queryOfficeName = queryParams.get('officeName'); 
      queryOfficeName? setOffice(queryOfficeName) : null;

      const queryPreStartDate = queryParams.get('preOperationStartDate'); 
      queryPreStartDate? setPreStartDate(queryPreStartDate) : setPreStartDate(null);
      const queryStartDate = queryParams.get('startDate'); 
      queryStartDate? setStartDate(queryStartDate) : setStartDate(null);
      const queryEndDate = queryParams.get('endDate'); 
      queryEndDate? setEndDate(queryEndDate) : setEndDate(null);

      const queryStartLTime = queryParams.get('eating_time'); 
      const eating_time = queryStartLTime?.split(",")
      eating_time?.forEach((obj: any, _) => {
        if (obj.includes("아침")){
          if (obj?.split("_").length == 2){
            if (obj?.split("_")[1]){
              const times = obj?.split("_")[1]?.split("~");
              setStartBTime(times.length >= 1? formatTime(times[0]): "");
              setEndBTime(times.length == 2? formatTime(times[1]): "");
            }
            else{
              setStartBTime("");
              setEndBTime("");
            }
          }
          else{
            setStartBTime("");
            setEndBTime("");
          }
        }
        else if (obj.includes("점심")){
          if (obj?.split("_").length == 2){
            if (obj?.split("_")[1]){
              const times = obj?.split("_")[1]?.split("~");
              setStartLTime(times.length >= 1? formatTime(times[0]): "");
              setEndLTime(times.length == 2? formatTime(times[1]): "");
            }
            else{
              setStartLTime("");
              setEndLTime("");
            }
          }
          else{
            setStartLTime("");
            setEndLTime("");
          }
        }
        else if (obj.includes("저녁")){
          if (obj?.split("_").length == 2){
            if (obj?.split("_")[1]){
              const times = obj?.split("_")[1]?.split("~");
              setStartDTime(times.length >= 1? formatTime(times[0]): "");
              setEndDTime(times.length == 2? formatTime(times[1]): "");
            }
            else{
              setStartDTime("");
              setEndDTime("");
            }
          }
          else{
            setStartDTime("");
            setEndDTime("");
          }
        }
        else if (obj.includes("야식")){
          if (obj?.split("_").length == 2){
            if (obj?.split("_")[1]){
              const times = obj?.split("_")[1]?.split("~");
              setStartNTime(times.length >= 1? formatTime(times[0]): "");
              setEndNTime(times.length == 2? formatTime(times[1]): "");
            }
            else{
              setStartNTime("");
              setEndNTime("");
            }
          }
          else{
            setStartNTime("");
            setEndNTime("");
          }
        }
      });

      setButtonClicked(true);
    }
    
  }, [location]);
  
  const onSendDepartmentRecord = () => {
    const fetchData = async () => {
      const body = {
        name: dpmtName,
        name_eng: dpmtNameEng,
        meal_count: mealCount === undefined ? null: mealCount,
        education_office: isOfficeDisabled === true ? null:office,
        main_category_id: main,
        sub_category_id: sub,
        service_type_id: serviceTypeId,
        type: type,
        b_start: startBTime !== "" ? startBTime : null,
        b_end: endBTime !== "" ? endBTime : null,
        l_start: startLTime !== "" ? startLTime : null,
        l_end: endLTime !== "" ? endLTime : null,
        d_start: startDTime !== "" ? startDTime : null,
        d_end: endDTime !== "" ? endDTime : null,
        n_start: startNTime !== "" ? startNTime : null,
        n_end: endNTime !== "" ? endNTime : null,
        pre_operation_start: preStartDate !== "" ? preStartDate : null,
        operation_start_date: startDate !== "" ? startDate : null,
        operation_end_date: endDate !== "" ? endDate : null,
        ba_type: baType,
        admin_user_name: userName !== "" ? userName : null,
        admin_user_email: emailAddress !== "" ? emailAddress : null,
        admin_user_phone_number: phoneNumber !== "" ? phoneNumber : null,
        monthly_option: dashboardOption === "0" ? 1 : 0,
        semester_option: dashboardOption === "1" ? 1 : 0,
        total_option: dashboardOption === "2" ? 1 : 0,
        department_establishment_category_id: main === 3 ? dpmtEstablishmentCategoryId : null, 
        country_id: countryId,
        state_id: stateId,
        city_id: cityId === undefined ? null: cityId,
        district_id: districtId === undefined ? null: districtId,
        road_address: roadAddress,
        building_address: buildingAddress,
      }
      console.log(body)
      try {
        await axiosInstance.post("/api/department", body)
        .then(response => {
          console.log(response)
          showModal(true, "Register Success", "department_id is "+ response.data.id as string);
        })
        .catch(e =>{
          console.log(e)
          showModal(false, "Register Fail", e.response.data.detail);
        });
      } catch (e) {
        showModal(false, "Register Fail");
        console.log(e)
      }
    };
    fetchData();
  };

  return (
    <div>
      <div className="column-layout">
        <div className="column">
          <DepartmentInfo />
        </div>
        <div className="column">
          <Address />
        </div>
        <div className="column">
          <DepartmentOperation />
        </div>
        <div className="column">
          <DashboardOption />
          <AdminUser />
        </div>
      </div>
      <Button 
        onClick={onSendDepartmentRecord} 
        disabled={ dpmtName === "" || isValidNameEng === 0  || serviceTypeId === undefined || countryId === null || sub === null ||
                   (countryId == 1 && stateId === null) || //국내 일땐 시/도 선택 필수 
                   (main === 3 && countryId == 1 && cityId === null) || //어린이집, 유치원 일땐 시/군/구 선택 필수
                   (main === 2 && (invalidMealCount || mealCount === null)) || //학교 일땐 급식인원수 선택 필수
                   (main === 3 && dpmtEstablishmentCategoryId === undefined ) || //어린이집, 유치원 일땐 설립유형 선택 필수
                   (main === 3 && (preStartDate === null || preStartDate === ""))  ||//어린이집, 유치원 일땐 개원예정일 선택 필수
                   (main === 3 && (roadAddress === null || roadAddress === "")) //어린이집, 유치원 일땐 도로명주소 선택 필수
                 }
      >send</Button>
    </div>
  );
};
export default DepartmentPage;
