import { useState } from "react";
import { DefaultOptionType } from "antd/es/select";
import { Form, Switch, Select, Radio, TimePicker } from "antd";
// import axios from "axios";
import axiosInstance from "../common/AxiosInstance";
import dayjs from "dayjs";

import { useRecoilState } from "recoil";
import {
  hwTypeState,
  scannerTypeState,
  uiIdState,
  inferIpState,
  stdVolumeState,
  offTimeState,
  edgeAiState
} from "@/components/scanner/recoil/scannerAtom";


interface UiMode {
  id: number,
  eng_ui: number,
  type: string,
  description: string
}

function ScannerSetting(_: any) {
  const [hwType, ] = useRecoilState(hwTypeState);
  const [scannerType, setScannerType] = useRecoilState(scannerTypeState);
  const [uiId,setUiId] = useRecoilState(uiIdState);
  const [inferIp, setInferIp] =  useRecoilState(inferIpState);
  const [stdVolume, setStdVolume] =  useRecoilState(stdVolumeState);
  const [offTime, setOffTime] =  useRecoilState(offTimeState);
  const [edgeAI, setEdgeAI] =  useRecoilState(edgeAiState);

  const [callEndpoint, setCallEndpoint] = useState<boolean>(false);
  const [uiList, setUiList] = useState<UiMode[]>([]);
  const urls: DefaultOptionType[] = [
    {
      value: "lemon.nuvi-lab.com",
      label: "lemon.nuvi-lab.com",
    }
  ];

  const ChangeScannerType = (value: string) => {
    const fetchData = async () =>{
      try {
        const { data } = await axiosInstance.get("/api/scanner/ui-setting/"+value);
        setUiList(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
    setScannerType(value);

    value === "class" || value === "id" ? setUiList([]): null;
    value === "plate" ? setUiId(3) : value === "rfid" ? setUiId(12) : value === "face" ? setUiId(14): setUiId(null);

    setCallEndpoint(true);
    setInferIp("lemon.nuvi-lab.com");
    setStdVolume(1);
    setOffTime("16:00");
  }
  const time_format = "HH:mm";

  return (
    <div>
      <h2>Scanner Setting</h2>
      <Form
        disabled = {hwType === "DD"?true:false}>
        <Form.Item label="Mode">
          <Radio.Group value={scannerType} onChange={(e) => ChangeScannerType(e.target.value)}>
            <Radio.Button value="plate">식판</Radio.Button>
            <Radio.Button value="rfid">RFID</Radio.Button>
            <Radio.Button value="face">안면인식</Radio.Button>
            <Radio.Button value="class">학년/반</Radio.Button>
            <Radio.Button value="id">학년/반/번호</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="UI">
          <Select
            value={uiId}
            onChange={(e) => setUiId(e)}
          >
            {uiList.map((uiMode: UiMode) => {
              return (
                <Select.Option key={uiMode.id} value={uiMode.id}>
                  {uiMode.description}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="실시간 분석" valuePropName="is_call_endpoint">
          <Switch checked={callEndpoint === true} onChange={setCallEndpoint} />
        </Form.Item>
        <Form.Item label="Edge AI" valuePropName="edge_ai">
          <Switch checked={edgeAI === true} onChange={setEdgeAI} />
        </Form.Item>
        <Form.Item label="IP" valuePropName="is_call_endpoint">
          <Select
            disabled={callEndpoint === false}
            value={inferIp}
            onChange={setInferIp}
            options={urls}
          ></Select>
        </Form.Item>
        <Form.Item label="표준 배식 촬영">
          <Switch
            checked={stdVolume===1?true:false}
            onChange={(e) => e===true? setStdVolume(1) : setStdVolume(0)}
          />
        </Form.Item>
        <Form.Item label="offtime">
          <TimePicker
            value={offTime ? dayjs(offTime, time_format): null}
            onChange={(_: any, dateStrings: string) => {setOffTime(dateStrings)}}
            // defaultValue={offTime}
            format={time_format}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ScannerSetting;
