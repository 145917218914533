import {
  Button,
  Upload,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Typography,
  message
} from "antd";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import "@/pages/GeneralPage.scss"


import { useRecoilState } from "recoil";
import { maxFaceKeyState } from "./recoil/userAtom";

export interface FaceUser{
  key: React.Key;
  user_id: string| null;
  name: string| null;
  department_id: number| null;
  phone_number: string| null;
  height: number | null;
  weight: number | null;
  birth_year: string | null;
  birth_month: string | null;
  birth_day: string | null;
  gender: 1 | 2 | null;
  student_id: string| null;
}

const numberList: string[] = ["user_id", "height", "weight", "birth_year", "birth_month", "birth_day"];
const requiredList: string[] = ["name", "department_id", "student_id"];

const filterOption = (input: string, option?: { key: string; value: number }) => {
  return (option?.key ?? '').toLowerCase().includes(input.toLowerCase());
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "genderCombo" | "dpmtCombo";
  record: FaceUser;
  index: number;
  children: React.ReactNode;
}

function FaceRegister(props: any) {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<string>("");

  const [maxKey, setMaxKey] = useRecoilState(maxFaceKeyState);
  const [user_id, setUserID] = useState<string | null>();

  // const [maxKey, setMaxKey] = useState<number>(
  //   Math.max(props.faceUsers.map((o:any) => o.key as number))
  // );

  const handleUserId = (e: any) =>{
    setUserID(e.target.value);
  }


  const validatePhoneNumber = (_: any, value: any) => {
    const regExp = /[^0-9]/;
    if (value === null){
      return Promise.resolve();
    }

    if (regExp.test(value)) {
      return Promise.reject(new Error('숫자만 사용할 수 있습니다.'));
    }
    if (value.length > 11){
      return Promise.reject(new Error('11자리를 넘을수 없습니다.'));
    }
    return Promise.resolve();
  };


  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "number" && dataIndex === "user_id" ? (
        <InputNumber
        min={0}
        onBlur = {handleUserId}
        />
      ) :
      inputType === "number" ? (
        <InputNumber min={0} />
      ) : inputType === "text" ? (
        <Input />
      ) : inputType === "genderCombo" ? (
        <Select
          options={[
            {
              value: null,
              label: "",
            },
            {
              value: 1,
              label: "male",
            },
            {
              value: 2,
              label: "female",
            },
          ]}
        />
      ) : (
        <Select
          showSearch
          placeholder="Select a department name"
          optionFilterProp="children"
          // onChange={(e) => props.setDpmtId(e)}
          filterOption={filterOption}
        >
          {props.dpmtNames.map((dpmt: any) => {
            return (
              <Select.Option key={dpmt.name} value={dpmt.id}>
                {dpmt.name}
              </Select.Option>
            );
          })}
        </Select>
      );
  
    return (
      <td {...restProps}>
        {editing ? 
          dataIndex!=="phone_number"? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: (user_id === "" || user_id === undefined || user_id === null) && requiredList.includes(dataIndex),
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                validator: validatePhoneNumber,
              }
            ]}
          >
            {inputNode}
          </Form.Item>
        )
        : (
          children
        )}
      </td>
    );
  };

  const isEditing = (record: FaceUser) => record.key === editingKey;

  const edit = (record: Partial<FaceUser> & { key: React.Key }) => {
    form.setFieldsValue({ name: "", age: "", address: "", ...record });
    setUserID(record.user_id ? record.user_id : null);
    setEditingKey(record.key as string);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as FaceUser;

      row.name === "" ? row.name = null : row.name;
      row.phone_number === "" ? row.phone_number = null : row.phone_number;

      row.birth_year === "" ? row.birth_year = null : row.birth_year;
      row.birth_month === "" ? row.birth_month = null : row.birth_month;
      row.birth_day === "" ? row.birth_day = null : row.birth_day;
      row.student_id === "" ? row.student_id = null : row.student_id;
      
      setUserID(undefined);

      const newData = [...props.faceUsers];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        props.setFaceUsers(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        props.setFaceUsers(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = (key: React.Key) => {
    const newData = props.faceUsers.filter((item:any) => item.key !== key);
    props.setFaceUsers(newData);
  };

  const columns = [
    {
      title: <span className="custom-red-column">이름</span> as unknown as string,
      dataIndex: "name",
      // width: "25%",
      editable: true,
    },
    {
      title: <span className="custom-red-column">기관명 (자동으로 id로 변환)</span> as unknown as string,
      dataIndex: "department_id",
      width: "15%",
      editable: true,
    },
    {
      title: <span className="custom-red-column">학번 (5자리)</span> as unknown as string,
      dataIndex: "student_id",
      // width: "40%",
      editable: true,
    },
    {
      // title: <span className="custom-red-column">핸드폰 번호</span>,
      title: "핸드폰 번호",
      dataIndex: "phone_number",
      // width: "40%",
      editable: true,
    },
    {
      title: "키",
      dataIndex: "height",
      // width: "40%",
      editable: true,
    },
    {
      title: "몸무게",
      dataIndex: "weight",
      // width: "40%",
      editable: true,
    },
    {
      title: "성별 (1: male, 2: female)",
      dataIndex: "gender",
      // width: "40%",
      editable: true,
    },
    {
      title: "생년",
      dataIndex: "birth_year",
      // width: "40%",
      editable: true,
    },
    {
      title: "생월",
      dataIndex: "birth_month",
      // width: "40%",
      editable: true,
    },
    {
      title: "생일",
      dataIndex: "birth_day",
      // width: "40%",
      editable: true,
    },
    {
      title: "누비 유저 id",
      dataIndex: "user_id",
      // width: "25%",
      editable: true,
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_: any, record: FaceUser) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>
              Cancel
            </Typography.Link>
          </span>
        ) : (
          <span>
            <Typography.Link
              style={{ marginRight: 10 }}
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
            <Typography.Link
              onClick={() => handleDelete(record.key)}
            >
              Delete
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: FaceUser) => ({
        record,
        inputType: numberList.includes(col.dataIndex) ? "number"
          : col.dataIndex === "gender" ? "genderCombo"
          : col.dataIndex === "department_id" ? "dpmtCombo"
          : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = () => {
    const newData: FaceUser = {
      key: maxKey,
      user_id: null,
      name: "임시",
      department_id: null,
      phone_number: null,
      height: null,
      weight: null,
      student_id: "00000",
      birth_year: null,
      birth_month: null,
      birth_day: null,
      gender: null,
    };
    setMaxKey(maxKey + 1);
    props.setFaceUsers([...props.faceUsers, newData]);
  };
  
  const deleteAllUser = () => {
    setEditingKey("");
    props.setFaceUsers([]);
    // console.log(props.users)
  };


  const handleDownload = () => {
    const csvData = [
      ["name", "department", "student_id", "phone_number", "height", "weight",  "gender(male:1, female:2)", "birth_year", "birth_month", "birth_day", "user_id"]
    ];
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'Face_Register.csv');
  };
  
  const handleCsvUpload = (file: any) => {
    Papa.parse(file, {
      complete: (result: any) => {
        result.data.forEach((obj: any, index:number) => {
          obj["key"] = maxKey+index;
          obj["department_id"] = props.dpmtNames.find((o:any) => o.name === obj["department"])?.id;
          obj["gender"] = obj["gender(male:1, female:2)"] === "" ? null : obj["gender(male:1, female:2)"]
          
          obj["phone_number"] = obj["phone_number"] === "" || obj["phone_number"] === undefined ? null : 
                                obj["phone_number"][0] !== "0" ? `0${obj["phone_number"]}` : obj["phone_number"];
          obj["phone_number"] = obj["phone_number"] !== null ? obj["phone_number"].replace(/-/g,"") : null;
          
          obj["user_id"] = obj["user_id"] === ""? null :obj["user_id"]
          obj["weight"] = obj["weight"] === "" ? null : obj["weight"]
          obj["height"] = obj["height"] === "" ? null : obj["height"]
          obj["birth_year"] = obj["birth_year"] === ""? null :obj["birth_year"]
          obj["birth_month"] = obj["birth_month"] === "" ? null : obj["birth_month"]
          obj["birth_day"] = obj["birth_day"] === "" ? null : obj["birth_day"]
          obj["student_id"] = obj["student_id"] === "" ? null : obj["student_id"]
          setMaxKey(maxKey+index+1);
          // console.log(obj)
        });
        props.setFaceUsers([...props.faceUsers, ...result.data]);
      },
      header: true, // 첫 번째 행을 헤더로 사용
    });
  }

  return (
    <>
      <Form.Item>
        <Button type="primary" onClick={handleDownload} >
          양식 다운로드
        </Button>
        <Upload 
          showUploadList = {false}
          beforeUpload={(file) => {
            if (!"csv".includes(file.name.split('.')[1])) {
              message.error('You can only upload csv file!');
              return Upload.LIST_IGNORE;
            }
            handleCsvUpload(file);
            return false;
        }}>
          <Button icon={<UploadOutlined />}>Face CSV Upload (UTF-8)</Button>
        </Upload>
      </Form.Item>
      <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 , marginRight:3}}>
        Add a row
      </Button>
      <Button onClick={deleteAllUser} type="primary" style={{ marginBottom: 16 }}>
        DELETE all row
      </Button>
      <Form form={form} component={false}>
        <Table
          className="custom-bold-label"
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={props.faceUsers}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>
    </>
  );
};

export default FaceRegister;
