import { Form, Radio, Switch } from "antd";

export interface ModeInfo {
  identification: string;
  standard_serving_amount: number;
  enable_edge_ai: boolean;
}

function Mode(props: any) {
  
  return (
    <>
      <h2>개인화</h2>
      <Form>
        <Form.Item label="Mode">
          <Radio.Group 
            value={props.info.identification} 
            onChange={(e) => props.setInfo({ ...props.info, identification: e.target.value})}
          >
            <Radio.Button value="plate">식판</Radio.Button>
            <Radio.Button value="rfid">RFID</Radio.Button>
            <Radio.Button value="face">안면인식</Radio.Button>
            <Radio.Button value="class">학년/반</Radio.Button>
            <Radio.Button value="id">학년/반/번호</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="표준 배식 촬영">
          <Switch 
            checked={
              props.info.standard_serving_amount === 1? true: false
            }
            onChange={
              checked => props.setInfo({ ...props.info, standard_serving_amount: checked===true? 1: 0})
            }
          />
        </Form.Item>
        <Form.Item label="Edge AI" valuePropName="edge_ai">
          <Switch 
            checked={props.info.enable_edge_ai === true}
            onChange={
              checked => props.setInfo({...props.info, enable_edge_ai: checked===true? true: false})
            } />
        </Form.Item>
      </Form>
    </>
  );
};

export default Mode;
