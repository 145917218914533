import { Modal } from "antd";

export const showModal = (isSuccess:boolean, title: string, content: string = "", isRefresh: boolean = true) => {
  if(isSuccess){
    Modal.success({
      title: title,
      content: content,
      onOk(){isRefresh?window.location.reload():null}, 
    });
  } else{
    Modal.error({
      title: title,
      content: content,
      onOk(){}, 
    });
  };
};


export const showModalMovePage = (isSuccess:boolean, title: string, content: string, url: string) => {
  if(isSuccess){
    Modal.success({
      title: title,
      content: content,
      onOk(){
        window.location.replace(url)
      }, 
    });
  } else{
    Modal.error({
      title: title,
      content: content,
      onOk(){
        window.location.replace(url)}, 
    });
  };
};
