import React from "react";
import CameraCommon, { CameraInfo } from "./CameraCommon";
import DepthDetector, { DepthInfo } from "./DepthDetector";

interface Props {
  realsense: CameraInfo;
  setRealsense: React.Dispatch<React.SetStateAction<CameraInfo>>;
  depthInfo: DepthInfo;
  setDepthInfo: React.Dispatch<React.SetStateAction<DepthInfo>>;
}

const Realsense: React.FC<Props> = ({
  realsense,
  setRealsense,
  depthInfo,
  setDepthInfo,
}) => {
  return (
    <div>
      <h2>realsense</h2>
      <CameraCommon info={realsense} setInfo={setRealsense} />
      <DepthDetector info={depthInfo} setInfo={setDepthInfo} />
    </div>
  );
};

export default Realsense;
