// import Login from "@/components/login/Login";
import CryptoJS from "crypto-js"
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Form, Input, Button } from 'antd';
import { showModalMovePage, showModal } from "@/utils/Modal";

import axiosInstance from "../common/AxiosInstance";


interface LoginInfo {
  username: string;
  password: string;
}

const hashPassword = (password: string, salt: string, iterations: number): string => {
  const hashedPassword = CryptoJS.PBKDF2(password, salt, { keySize: import.meta.env.VITE_APP_SIZE as unknown as number, iterations, hasher: CryptoJS.algo.SHA256 });
  return hashedPassword.toString(CryptoJS.enc.Hex);
};

function Login() {
  const { control, handleSubmit } = useForm<LoginInfo>({mode:"onChange"});

  const onSubmit: SubmitHandler<LoginInfo> = (data) => {
    const hashedPassword = hashPassword(data.password, import.meta.env.VITE_APP_SALT as string, import.meta.env.VITE_APP_ITER as unknown as number)
    const fetchData = async () => {
      try {
        await axiosInstance.post("/api/auth/login", {
          username: data.username,
          password: hashedPassword
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        }).then(response => {
          console.log(response)
          window.localStorage.setItem("access_token", response.data.access_token);
          window.localStorage.setItem("login_id",  data.username);
          window.localStorage.setItem("refresh_token", response.data.refresh_token);
          showModalMovePage(true, "Login Success", "", "/department");
        });
      } catch (e) {
        console.log(e);
        showModal(false, "Login Fail");
      }
    };
    fetchData();
  };

  return (
    <Form onFinish={handleSubmit(onSubmit)}>
      <Controller
        name="username"
        control={control}
        defaultValue=""
        rules={{
          required: '아이디를 입력하세요.',
          pattern: {
            value: /^[a-zA-Z0-9]*$/,
            message: '영문자 및 숫자만 입력하세요.',
          },
        }}
        render={({ field, fieldState }) => (
          <Form.Item
            label="아이디"
            hasFeedback
            validateStatus={fieldState.invalid ? 'error' : ''}
            help={fieldState.invalid ? fieldState?.error?.message : null}
          >
            <Input {...field} placeholder="아이디" />
          </Form.Item>
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{
          required: '비밀번호를 입력하세요.',
          pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            message: '영문자 및 숫자를 포함한 8자 이상의 비밀번호를 입력하세요.',
          },
        }}
        render={({ field, fieldState }) => (
          <Form.Item
            label="비밀번호"
            hasFeedback
            validateStatus={fieldState.invalid ? 'error' : ''}
            help={fieldState.invalid ? fieldState?.error?.message : null}
          >
            <Input.Password {...field} placeholder="비밀번호" />
          </Form.Item>
        )}
      />
      <Button type="primary" htmlType="submit">
        확인
      </Button>
    </Form>
  );
};

export default Login;
