import RFIDRegister, {RFIDUser} from "@/components/user/RFIDRegister";
import FaceRegister, {FaceUser} from "@/components/user/FaceRegister";
import TouchRegister, {TouchUser} from "@/components/user/TouchRegister";
import {showModal} from "@/utils/Modal";

import { Button, Form, Radio } from "antd";
import { useState, useEffect } from "react";
// import axios from "axios";
import axiosInstance from "@/components/common/AxiosInstance";


function UserPage() {
  const [identificationType, setIdentificationType] = useState<number>(0);
  const [rfidUsers, setRFIDUsers] = useState<RFIDUser[]>([]);
  const [faceUsers, setFaceUsers] = useState<FaceUser[]>([]);
  const [touchUsers, setTouchUsers] = useState<TouchUser[]>([]);

  let [dpmtNames, setDpmtNames] = useState<any>([])

  useEffect(() => {
    const fetchData = async () =>{
      try {
        const { data } = await axiosInstance.get("/api/department");
        const result = data.map((obj: any) => {
          const selectedValues: Partial<any> = {};
          ["id","name","department_main_category_id"].forEach(key => {
            selectedValues[key] = obj[key];
          });
          return selectedValues;
        });
        setDpmtNames(result);
      } catch (e) {
        console.log(e);
      }
      };
    fetchData();
  }, []);

  const onSendRegisterUser = () =>{
    const fetchData = async () => {
      let url = "";
      let userInfo = null;
      if (identificationType === 0){
        url = "/api/user/rfid";
        userInfo = rfidUsers;
      }
      else if(identificationType === 1){
        url = "/api/user/face";
        userInfo = faceUsers;
      }
      else if(identificationType === 2){
        url = "/api/user/touch";
        userInfo = touchUsers;
      }
      try {
        // console.log(userInfo);
        await axiosInstance.post(url, {
          user_infos:userInfo
        })
        .then(response => {
          // console.log(response.data)
          showModal(true, "Register Success", "", false);
          response.data.user_infos.forEach((obj: any, index:number) => {
            obj["key"] = index;
          });

          if (identificationType === 0){
            setRFIDUsers([...response.data.user_infos]);
          }
          else if(identificationType === 1){
            setFaceUsers([...response.data.user_infos]);
          }
          else if(identificationType === 2){
            setTouchUsers([...response.data.user_infos]);
          }

        })
        .catch(e => {
          console.log(e)
          showModal(false, "Register Fail", "please write recomended field");
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }
  
  const onSoftDeleteUser = () => {
    const fetchData = async () => {
      let url = "";
      let userInfo = null;
      if (identificationType === 0){
        url = "/api/user/rfid";
        console.log(rfidUsers);
        userInfo = rfidUsers;
      }
      else if(identificationType === 1){
        url = "/api/user/face";
        userInfo = faceUsers;
      }
      else if(identificationType === 2){
        url = "/api/user/touch";
        userInfo = touchUsers;
      }
      try {
        console.log(userInfo);
        await axiosInstance.delete(url, {
          data:{
            user_infos:userInfo
          }
        })
        .then(response => {
          console.log(response.data)
          showModal(true, "Delete Success", "", false);
          response.data.user_infos.forEach((obj: any, index:number) => {
            obj["key"] = index;
          });

          if (identificationType === 0){
            setRFIDUsers([...response.data.user_infos]);
          }
          else if(identificationType === 1){
            setFaceUsers([...response.data.user_infos]);
          }
          else if(identificationType === 2){
            setTouchUsers([...response.data.user_infos]);
          }

        })
        .catch(e => {
          console.log(e)
          showModal(false, "Delete Fail", "please write recomended field");
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }

  // const onDownloadCSV = () => {
  //   TouchRegister.handleDownload
  // }

  return (
    <div>
      <Form>
        <Form.Item label="개인화 타입">
          <Radio.Group value={identificationType} onChange={(e) => setIdentificationType(e.target.value)}>
            <Radio.Button value={0}>RFID</Radio.Button>
            <Radio.Button value={1}>얼굴인식</Radio.Button>
            <Radio.Button value={2}>KidsTouch</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
      <Form>
        {identificationType === 0 ? (
          <RFIDRegister 
            dpmtNames={dpmtNames} setDpmtNames={setDpmtNames}
            rfidUsers={rfidUsers} setRFIDUsers={setRFIDUsers}/>
        ) : 
        identificationType === 1 ? (
          <FaceRegister 
            dpmtNames={dpmtNames} setDpmtNames={setDpmtNames}
            faceUsers={faceUsers} setFaceUsers={setFaceUsers}/>
        ):(
          <TouchRegister 
            dpmtNames={dpmtNames}
            touchUsers={touchUsers} setTouchUsers={setTouchUsers}/>
        )}
      </Form>
      <Button
        onClick={onSendRegisterUser}
        disabled={ identificationType === 0 ? rfidUsers.length<=0 ? true: false
                                            : identificationType === 1 
                                            ? faceUsers.length<=0 ? true: false
                                            : touchUsers.length<=0 ? true: false}
      >
        Register
      </Button>
      
      <Button
        disabled={ identificationType === 0 ? rfidUsers.length<=0 ? true: false
                                            : identificationType === 1 
                                            ? faceUsers.length<=0 ? true: false
                                            : touchUsers.length<=0 ? true: false}
        onClick={onSoftDeleteUser}>
          soft delete user
      </Button>
      {/* <Button
        onClick={onDownloadCSV}>
          Download Table
      </Button> */}

    </div>
  );
};

export default UserPage;
