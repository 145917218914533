import { atom } from "recoil";

export interface Department {
  id: number
  name: string;
  name_eng: string;
  type: string;
  education_office: string;
  department_main_category_id: number;
  department_sub_category_id: number;
}


export interface HwType{
  id: number;
  initial: string;
  model_name: string;
  type: string;
}

export const departmentsState = atom<Department[]>({
  key: 'departmentsState',
  default: [],
});

export const departmentIDState = atom<number>({
  key: 'departmentIDState',
  default: undefined,
});

export const hwTypesState = atom<HwType[]>({
  key: 'hwTypesState',
  default: [],
})

export const hwTypeState = atom<string|null>({
  key: 'hwTypeState',
  default: null,
});

export const beforeScannerCountState = atom<number>({
  key: 'beforeScannerCountState',
  default: 0,
});

export const beforeScannerSerialNumberListState = atom<string[]>({
  key: 'beforeScannerSerialNumberListState',
  default: [],
});

export const afterScannerCountState = atom<number>({
  key: 'afterScannerCountState',
  default: 0,
});

export const afterScannerSerialNumberListState = atom<string[]>({ 
  key: 'afterScannerSerialNumberListState',
  default: [],
});

export const scannerTypeState = atom<string|null>({
  key: 'scannerTypeState',
  default: null,
});

export const uiIdState = atom<number|null>({
  key: 'uiIdState',
  default: null,
});

export const inferIpState = atom<string|null>({
  key: 'inferIpState',
  default: null,
});

export const stdVolumeState = atom<number>({
  key: 'stdVolumeState',
  default: 0,
});

export const offTimeState = atom<string>({
  key: 'offTimeState',
  default: "16:00",
});

export const edgeAiState = atom<boolean>({
  key: 'edgeAiState',
  default: false,
});