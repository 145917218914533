import { Form, InputNumber } from "antd";
import React from "react";

export interface FaceInfo {
  roi_xmax: number; // 얼굴 인식 카메라에서 던져주는 사진을 화면에 잘라서 표출시 사용
  roi_xmin: number;
  roi_ymax: number;
  roi_ymin: number;
  face_view: number; // 얼굴 인식 화면 보여줄지 말지 옛날 여자고등학교에서 사용
  resolution_h: number; // 카메라에서 던져주는 사진 해상도
  resolution_w: number;
  min_face_size: number; // 얼굴의 최소 픽셀 크기
  delay_to_look_front: number;
  sensetime_local_db_sync: number;
}

interface Props {
  info: FaceInfo;
  setInfo: React.Dispatch<React.SetStateAction<FaceInfo>>;
}

const Face: React.FC<Props> = ({ info, setInfo }) => {
  const min = 0;
  const max = 1400;
  return (
    <div>
      <h2>Face</h2>
      <Form.Item label="min_face_size">
        <InputNumber
          min={min}
          max={max}
          value={info.min_face_size}
          onChange={(v) => setInfo({ ...info, min_face_size: v as number })}
        />
      </Form.Item>
      <Form.Item label="delay_to_look_front">
        <InputNumber
          min={min}
          max={max}
          value={info.delay_to_look_front}
          onChange={(v) =>
            setInfo({ ...info, delay_to_look_front: v as number })
          }
        />
      </Form.Item>
      <Form.Item label="face_view">
        <InputNumber
          min={min}
          max={max}
          value={info.face_view}
          onChange={(v) => setInfo({ ...info, face_view: v as number })}
        />
      </Form.Item>
      <Form.Item label="db_sync">
        <InputNumber
          min={min}
          max={max}
          value={info.sensetime_local_db_sync}
          onChange={(v) =>
            setInfo({ ...info, sensetime_local_db_sync: v as number })
          }
        />
      </Form.Item>
      <Form.Item label="resolution_w">
        <InputNumber
          min={min}
          max={max}
          value={info.resolution_w}
          onChange={(v) => setInfo({ ...info, resolution_w: v as number })}
        />
      </Form.Item>
      <Form.Item label="resolution_h">
        <InputNumber
          min={min}
          max={max}
          value={info.resolution_h}
          onChange={(v) => setInfo({ ...info, resolution_h: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_xmin">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_xmin}
          onChange={(v) => setInfo({ ...info, roi_xmin: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_ymin">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_ymin}
          onChange={(v) => setInfo({ ...info, roi_ymin: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_xmax">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_xmax}
          onChange={(v) => setInfo({ ...info, roi_xmax: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_ymax">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_ymax}
          onChange={(v) => setInfo({ ...info, roi_ymax: v as number })}
        />
      </Form.Item>
    </div>
  );
};

export default Face;
