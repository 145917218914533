import { atom } from "recoil";

export const maxRFIDKeyState = atom<number>({
  key: 'maxRFIDKeyState',
  default: 0,
});

export const maxFaceKeyState = atom<number>({
  key: 'maxFaceKeyState',
  default: 0,
});

export const maxTouchKeyState = atom<number>({
  key: 'maxTouchKeyState',
  default: 0,
});