import { Form, InputNumber } from "antd";

export interface DepthInfo {
  iter_to_init: number;
  top_area_ymax: number;
  top_area_ymin: number;
  left_area_xmax: number;
  left_area_xmin: number;
  plate_depth_max: number;
  plate_depth_min: number;
  point_max_count: number;
  point_min_count: number;
  right_area_xmax: number;
  right_area_xmin: number;
  bottom_area_ymax: number;
  bottom_area_ymin: number;
  depth_change_min: number;
  turn_off_delay_sec: number;
  check_value_for_reset: number;
  min_count_for_stability: number;
}

interface Props {
  info: DepthInfo;
  setInfo: React.Dispatch<React.SetStateAction<DepthInfo>>;
}

const DepthDetector: React.FC<Props> = ({ info, setInfo }) => {
  const min = 1;
  const max = 2000;
  return (
    <div>
      <h2>depth_detector</h2>
      <Form.Item label="left_area_xmin">
        <InputNumber
          min={min}
          max={max}
          value={info.left_area_xmin}
          onChange={(v) => setInfo({ ...info, left_area_xmin: v as number })}
        />
      </Form.Item>
      <Form.Item label="left_area_xmax">
        <InputNumber
          min={min}
          max={max}
          value={info.left_area_xmax}
          onChange={(v) => setInfo({ ...info, left_area_xmax: v as number })}
        />
      </Form.Item>
      <Form.Item label="right_area_xmin">
        <InputNumber
          min={min}
          max={max}
          value={info.right_area_xmin}
          onChange={(v) => setInfo({ ...info, right_area_xmin: v as number })}
        />
      </Form.Item>
      <Form.Item label="right_area_xmax">
        <InputNumber
          min={min}
          max={max}
          value={info.right_area_xmax}
          onChange={(v) => setInfo({ ...info, right_area_xmax: v as number })}
        />
      </Form.Item>
      <Form.Item label="top_area_ymin">
        <InputNumber
          min={min}
          max={max}
          value={info.top_area_ymin}
          onChange={(v) => setInfo({ ...info, top_area_ymin: v as number })}
        />
      </Form.Item>
      <Form.Item label="top_area_ymax">
        <InputNumber
          min={min}
          max={max}
          value={info.top_area_ymax}
          onChange={(v) => setInfo({ ...info, top_area_ymax: v as number })}
        />
      </Form.Item>
      <Form.Item label="bottom_area_ymin">
        <InputNumber
          min={min}
          max={max}
          value={info.bottom_area_ymin}
          onChange={(v) => setInfo({ ...info, bottom_area_ymin: v as number })}
        />
      </Form.Item>
      <Form.Item label="bottom_area_ymax">
        <InputNumber
          min={min}
          max={max}
          value={info.bottom_area_ymax}
          onChange={(v) => setInfo({ ...info, bottom_area_ymax: v as number })}
        />
      </Form.Item>
      <Form.Item label="min_count_for_stability">
        <InputNumber
          min={min}
          max={max}
          value={info.min_count_for_stability}
          onChange={(v) =>
            setInfo({ ...info, min_count_for_stability: v as number })
          }
        />
      </Form.Item>
    </div>
  );
};

export default DepthDetector;
