import { Form, Radio, RadioChangeEvent, Select, Alert, Space, Input } from "antd";
import Search from "antd/es/input/Search";
// import axios from "axios";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form"
import axiosInstance from "@/components/common/AxiosInstance";
import "@/pages/GeneralPage.scss"

import { useRecoilState } from "recoil";
import {
  departmentsState,
  categoriesState,
  mainState,
  subState,
  isOfficeDisabledState,
  dpmtNameState,
  dpmtNameEngState,
  isValidNameState,
  isValidNameEngState,
  mealCountState,
  invalidMealCountState,
  officesState,
  officeState,
  dpmtEstablishmentCategoryIdState,
  serviceTypeIdState,
  startDateState,
  endDateState,
  startBTimeState,
  endBTimeState,
  startLTimeState,
  endLTimeState,
  startDTimeState,
  endDTimeState,
  startNTimeState,
  endNTimeState,
  dashboardOptionState,
  userNameState,
  emailAddressState,
  phoneNumberState,
  countryIdState,
  stateIdState,
  cityIdState,
  districtIdState,
  roadAddressState,
  buildingAddressState,
  preStartDateState,
  buttonClickedState,
  dpmtEstablishmentCategoryState,
  baTypeState,
} from "@/components/department/recoil/departmentAtom";

interface Departments{
  name: string;
  eng_name: string;
  meal_count: number;
}

interface EstablishmentCategory{
  id: number
  name: string
  department_sub_category_id: number
}

function DepartmentInfo() {
  const [departments, setDepartments] = useRecoilState(departmentsState);
  const [categories, setCategories] = useRecoilState(categoriesState);
  const [main, setMain] = useRecoilState(mainState);
  const [sub, setSub] = useRecoilState(subState);
  const [isOfficeDisabled, setIsOfficeDisabled] = useRecoilState(isOfficeDisabledState);
  const [dpmtName, setDpmtName] = useRecoilState(dpmtNameState);
  const [dpmtNameEng, setDpmtNameEng] = useRecoilState(dpmtNameEngState);
  const [, setIsValidName] = useRecoilState(isValidNameState);
  const [isValidNameEng, setIsValidNameEng] = useRecoilState(isValidNameEngState);
  const [mealCount, setMealCount] = useRecoilState(mealCountState);
  const [, setInvalidMealCount] = useRecoilState(invalidMealCountState);
  const [offices, ] = useRecoilState(officesState);
  const [office, setOffice] = useRecoilState(officeState);
  const [dpmtEstablishmentCategoryId, setDpmtEstablishmentCategoryId] = useRecoilState(dpmtEstablishmentCategoryIdState);
  const [, setServiceTypeId] = useRecoilState(serviceTypeIdState);
  const [, setPreStartDate] = useRecoilState(preStartDateState);
  const [, setStartDate] = useRecoilState(startDateState);
  const [, setEndDate] = useRecoilState(endDateState);
  const [, setStartBTime] = useRecoilState(startBTimeState);
  const [, setEndBTime] = useRecoilState(endBTimeState);
  const [, setStartLTime] = useRecoilState(startLTimeState);
  const [, setEndLTime] = useRecoilState(endLTimeState);
  const [, setStartDTime] = useRecoilState(startDTimeState);
  const [, setEndDTime] = useRecoilState(endDTimeState);
  const [, setStartNTime] = useRecoilState(startNTimeState);
  const [, setEndNTime] = useRecoilState(endNTimeState);
  const [, setDashboardOption] = useRecoilState(dashboardOptionState);
  const [, setUserName] = useRecoilState(userNameState);
  const [, setEmailAddress] = useRecoilState(emailAddressState);
  const [, setPhoneNumber] = useRecoilState(phoneNumberState);
  const [, setCountryId] = useRecoilState(countryIdState);
  const [, setStateId] = useRecoilState(stateIdState);
  const [, setCityId] = useRecoilState(cityIdState);
  const [, setDistrictId] = useRecoilState(districtIdState);
  const [, setRoadAddress] = useRecoilState(roadAddressState);
  const [, setBuildingAddress] = useRecoilState(buildingAddressState);
  const [, setBAType] = useRecoilState(baTypeState);
  const [buttonClicked, setButtonClicked] = useRecoilState(buttonClickedState);
  const [dpmtEstablishmentCategory, ] = useRecoilState(dpmtEstablishmentCategoryState);


  const [dpmtEstablishmentCategories, setDpmtEstablishCategories] = useState<EstablishmentCategory[]>();

  const { control, trigger, setValue } = useForm<Departments>({mode:"onChange"});

  useEffect(() => {
    if (buttonClicked){
      setValue('eng_name', dpmtNameEng);
      trigger("eng_name");
    }
  },[dpmtNameEng])


  useEffect(() => {
    if (buttonClicked){
      setValue('meal_count', mealCount?mealCount:0);
      trigger("meal_count");
    }
  },[mealCount])

  useEffect(() => {
    setIsOfficeDisabled(main === 1 || 
                       (main === 3 && sub !== 8) || 
                       (main === 2 && sub === 6));

    
    main === 3 && (sub === 7 || sub === 8) ? onSearchEstablishmentCategories(sub) : null;
  }, [main, sub])

  useEffect(() =>{
    if (buttonClicked){
      const establishCategories = sub? onSearchEstablishmentCategories(sub): null;
      
      if(establishCategories){
        establishCategories.then((searchEstablishList: EstablishmentCategory[]) =>{
          setDpmtEstablishmentCategoryId((searchEstablishList.filter((item: EstablishmentCategory) => dpmtEstablishmentCategory?.includes(item.name)))[0].id);
        })
      }
    }
  }, [dpmtEstablishmentCategory])

  useEffect(() => {
    const fetchMainCategories = async () => {
      try {
        const { data } = await axiosInstance.get("/api/department/main-categories");
        setCategories(data);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchDepartments = async () =>{
      try {
        const { data } = await axiosInstance.get("/api/department");
        setDepartments(data);
      } catch (e) {
        console.log(e);
      }
    };
    
    fetchMainCategories();
    fetchDepartments();
  }, []);

  const uniqueMainCategory = [...new Map(categories.map((item: any) =>
                                    [item["main_category_id"], item])).values()];

  const handleMainCategoryChange = (e: RadioChangeEvent) => {
    setMain(e.target.value);
    setSub(undefined);
    // setDpmtEstablishmentCategory(undefined);
    setDpmtEstablishmentCategoryId(undefined);

    // setIsOfficeDisabled(e.target.value === 1 || 
    //                     (e.target.value === 3 && sub !== 8) || 
    //                     (e.target.value === 2 && sub === 6));
  };

  const handleSubCatergoryChange = (e: RadioChangeEvent) => {
    // setIsOfficeDisabled(main === 1 || 
    //                     (main === 3 && e.target.value !== 8) || 
    //                     (main === 2 && e.target.value === 6));

    setSub(e.target.value);
    // const type = props.categories.filter((item: any) => item.sub_category_id === e.target.value)
    // props.setType(type[0].sub_name)

    // setDpmtEstablishmentCategory(undefined);
    setDpmtEstablishmentCategoryId(undefined);
    
    main === 3 ? onSearchEstablishmentCategories(e.target.value) : null
  }

  const dpmtNameSearch = (value: string) => {
    // const searched = props.departments.filter((item: any) => item.name === value);
    setIsValidName(value === "" ? 
                                    0 : value.length === 0 ? 
                                    -1 : 1);
    setDpmtName(value);
  }

  const dpmtNameEngSearch = (value: string, invalid: boolean) => {
    const searched = departments.filter((item: any) => item.name_eng === value);
    const isValidNameEng = value === "" || invalid === true ?
                                                          0 : searched.length === 0 ? 
                                                          1 : -1

    setIsValidNameEng(isValidNameEng);

    if (isValidNameEng === -1) {
      const fetchData = async () =>{
        try {
          const { data } = await axiosInstance.get("/api/department/"+value);
          console.log(data)
          setButtonClicked(true);
          
          setDpmtName(data.name);
          setDpmtNameEng(data.name_eng);

          setInvalidMealCount(false);
          setMealCount(data.meal_count);

          setOffice(data.education_office);
          setIsOfficeDisabled((data.education_office === null || data.education_office === "") ? true : false);

          setMain(data.main_category_id);
          setSub(data.sub_category_id);

          data.main_category_id === 3 ? onSearchEstablishmentCategories(data.sub_category_id) : null;
          //const searchEstablishs = 
          setDpmtEstablishmentCategoryId(data.department_establishment_category_id);
          
          // if( data.main_category_id === 3 && searchEstablishs){
          //   searchEstablishs.then((searchEstablishList: EstablishmentCategory[]) =>{
          //     setDpmtEstablishmentCategory((searchEstablishList.filter((item: EstablishmentCategory) => item.id === data.department_establishment_category_id))[0].name);
          //   })
          // }

          setServiceTypeId(data.service_type_id);
          setPreStartDate(data.pre_operation_start)
          setStartDate(data.operation_start_date);
          setStartDate(data.operation_start_date);
          setEndDate(data.operation_end_date); 
          setStartBTime(data.b_start);
          setEndBTime(data.b_end);
          setStartLTime(data.l_start);
          setEndLTime(data.l_end);
          setStartDTime(data.d_start);
          setEndDTime(data.d_end);
          setStartNTime(data.n_start);
          setEndNTime(data.n_end);
          setBAType(data.ba_type);

          setDashboardOption(data.monthly_option === 1 ? 
                                                     "0" : data.semester_option === 1 ? 
                                                     "1" : data.total_option === 1 ? 
                                                     "2" : "0");

          setUserName(data.admin_user_name);
          setEmailAddress(data.admin_user_email);
          setPhoneNumber(data.admin_user_phone_number);

          setCountryId(data.country_id)
          setStateId(data.state_id);
          setCityId(data.city_id);
          setDistrictId(data.district_id);
          setRoadAddress(data.road_address);
          setBuildingAddress(data.building_address);
          
          setValue('meal_count', data.meal_count);
          // trigger("meal_count");
        } catch (e) {
          console.log(e);
        }
      };
      fetchData();
    }
  }

  const onSearchEstablishmentCategories = (sub_category_id:number) => {
    const fetchData = async () =>{
      try {
        const { data } = await axiosInstance.get("/api/department/establishment-categories/"+sub_category_id);
        setDpmtEstablishCategories(data);
        return data
      } catch (e) {
        console.log(e);
      }
    };
    const data = fetchData();
    return data;
  }

  const handleDpmtEstablishmentCategory = (_: number, option: any) =>{
    setDpmtEstablishmentCategoryId(option.value);
    // setDpmtEstablishmentCategory(value);
  }
  
  
  return (
    <div>
      <h2>Department</h2>
      <Form>
        <Form.Item 
          className="custom-red-label"
          label="NAME"
        >
          <Input 
            value={dpmtName}
            placeholder="Input department kor name"
            onChange={(e) => dpmtNameSearch(e.target.value)}/>
        </Form.Item>
        <Controller 
          name="eng_name"
          control={control}
          defaultValue={dpmtNameEng}
          rules={{
            required: "기관 영문명을 입력하세요.",
            pattern: {
              value: /^[a-zA-Z0-9-]*$/,
              message: '영어만 입력하세요.', 
            },
          }}
          render={({ field, fieldState }) => (
            <Form.Item 
              className="custom-red-label"
              label="ENG"
              hasFeedback
              validateStatus={fieldState.invalid ? 'error' : ''}
              help={fieldState.invalid ? fieldState?.error?.message : null}
            >
              <Search
                {...field}
                placeholder="Input department eng name"
                enterButton="Search"
                onSearch={() => dpmtNameEngSearch(field.value, fieldState.invalid)}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("eng_name");
                  setIsValidNameEng(0);
                  setDpmtNameEng(e.target.value)
                }}
              />
              <Space direction="vertical" style={{ width: '100%' }}>
                {isValidNameEng === 0 ? "":
                  isValidNameEng === 1 ? <Alert message="register name" type="success" />:
                                              <Alert message="update department" type="error" />}
              </Space>
            </Form.Item>
          )}
        />
        <Form.Item 
          className="custom-red-label"
          label="Main Catergory">
          <Radio.Group value={main} onChange={handleMainCategoryChange}>
            {uniqueMainCategory.map((category: any) => {
              return (
                <Radio.Button key={category.main_category_id} value={category.main_category_id}>
                  {category.main_name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          className="custom-red-label"
          label="Sub Category">
          <Radio.Group value={sub} onChange={handleSubCatergoryChange}>
            {categories.map((category: any) => {
              return main === category.main_category_id ? (
                <Radio.Button key={category.sub_category_id} value={category.sub_category_id}>
                  {category.sub_name}
                </Radio.Button>
              ) : null;
            })}
          </Radio.Group>
        </Form.Item>
        <Form.Item 
          className={main===3? "custom-red-label":""}
          label="Establish Category">
          <Select
            value={dpmtEstablishmentCategoryId}
            disabled={main !== 3} 
            showSearch
            placeholder="Select a Establish Category"
            onChange={handleDpmtEstablishmentCategory}
          >
            {dpmtEstablishmentCategories?.map((category: EstablishmentCategory) => {
              return (
                <Select.Option key={category.name} value={category.id}>
                  {category.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item 
          className={isOfficeDisabled === false ? "custom-red-label":""}
          label="교육청">
          <Select
            disabled={isOfficeDisabled }
            value={office}
            onChange={(value) => setOffice(value)}
          >
            {offices.map((office: any) => {
              return (
                <Select.Option key={office} value={office}>
                  {office}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Controller
          name="meal_count"
          control={control}
          defaultValue={mealCount}
          rules={{ 
            required: '식수를 입력하세요.',
            pattern: {
              value: /^[0-9]*$/,
              message: '숫자만 입력하세요.', 
            },
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              className={main===2? "custom-red-label": ""}
              label="식수"
              hasFeedback
              validateStatus={fieldState.invalid ? 'error' : ''}
              help={fieldState.invalid ? fieldState?.error?.message : null}
            >
              <Input 
                {...field} 
                placeholder="meal_count" 
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setMealCount(e.target.value === ""? undefined : e.target.value as unknown as number);
                  setInvalidMealCount(fieldState.invalid)
                }}
              />
            </Form.Item>
          )}/>
      </Form>
    </div>
  );
};

export default DepartmentInfo;
