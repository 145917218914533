import { Form, InputNumber, Select, message } from "antd";
// import axios from "axios";
// import axiosInstance from "../common/AxiosInstance";
// import { useEffect, useState } from "react";
import { Option } from "antd/lib/mentions";
import { useRecoilState } from "recoil";
import {
  departmentsState,
  departmentIDState,
  hwTypesState,
  hwTypeState,
  beforeScannerCountState,
  afterScannerCountState,
  beforeScannerSerialNumberListState,
  afterScannerSerialNumberListState
  // scannerTypeState,
  // uiIdState,
  // inferIpState,
  // stdVolumeState,
  // offTimeState
} from "@/components/scanner/recoil/scannerAtom";


function ScannerInfo(_: any) {
  const [hwTypes, ] = useRecoilState(hwTypesState);
  const [departments, ] = useRecoilState(departmentsState);
  const [dpmtId, setDpmtId] = useRecoilState(departmentIDState);
  const [hwType, setHwType] = useRecoilState(hwTypeState);
  const [afterCount, setAfterCount] = useRecoilState(afterScannerCountState);
  const [beforeCount, setBeforeCount] = useRecoilState(beforeScannerCountState);
  const [beforeSerialNumbers, setBeforeSerialNumbers] = useRecoilState(beforeScannerSerialNumberListState);
  const [afterSerialNumbers, setAfterSerialNumbers] = useRecoilState(afterScannerSerialNumberListState);

  const formatRegex = /^VS-\d{10}$/; // "VS-"로 시작하고 10개의 문자로 구성된 형식

  const filterOption = (input: string, option?: { key: string; value: number }) => {
    return (option?.key ?? '').toLowerCase().includes(input.toLowerCase());
  }

  const handleHwType = (initial: string) => {
    setHwType(initial);
    initial==="DD"?setAfterCount(0):null
  }

  const handleBeforeSerialNumbers = (values: string[]) => {
    if (values.length > beforeCount) {
      message.warning(`You can only add up to ${beforeCount} tags`);
    } else {
      const isValid = values.every(tag => formatRegex.test(tag));
      if (!isValid) {
        message.error('Each tag must match the format "VS-YYMMDDNNNN"');
      } else {
        setBeforeSerialNumbers(values);
      }
    }
  }

  const handleAfterSerialNumbers = (values: string[]) => {
    if (values.length > afterCount) {
      message.warning(`You can only add up to ${afterCount} tags`);
    } else {
      const isValid = values.every(tag => formatRegex.test(tag));
      if (!isValid) {
        message.error('Each tag must match the format "VS-YYMMDDNNNN"');
      } else {
        setAfterSerialNumbers(values);
      }
    }
  }

  return (
    <div>
      <h2>Scanner Info</h2>
      <Form>
        <Form.Item label="기관명"
          help={dpmtId === null || dpmtId === undefined  ? "not exist department name" : null}
          validateStatus={dpmtId === null || dpmtId === undefined   ? 'error' : ''}>
          <Select
            showSearch
            placeholder="Select a department name"
            optionFilterProp="children"
            onChange={(e) => setDpmtId(e)}
            filterOption={filterOption}
            status={dpmtId === null || dpmtId === undefined ? "error": ""}
            value={dpmtId}
          >
            {departments.map((dpmt: any) => {
              return (
                <Select.Option key={dpmt.name} value={dpmt.id}>
                  {dpmt.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="하드웨어 타입">
          <Select
            value={hwType}
            onChange={(e) => handleHwType(e)}
          >
            {hwTypes.map((hwType: any) => {
              return (
                <Select.Option key={hwType.type} value={hwType.initial}>
                  {hwType.type}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="배식 스캐너 수">
          <InputNumber
            value={beforeCount}
            onChange={(value) => {
              setBeforeCount(value as number);
            }}
            min={0}>
          </InputNumber>
          { hwType==="VS" &&
            <Select 
              mode="tags"
              value={beforeSerialNumbers}
              style={{ width: '100%' }}
              onChange={handleBeforeSerialNumbers}
              maxTagCount={beforeCount}
              placeholder="Please select"
            >
              {beforeSerialNumbers.map((serialNumber: string) => (
                <Option key={serialNumber} value={serialNumber}>
                  {serialNumber}
                </Option>
              ))}
            </Select>
          }
        </Form.Item>
        <Form.Item label="퇴식 스캐너 수">
          <InputNumber
            disabled={hwType==="DD"?true:false}
            value={afterCount}
            onChange={(value) => {
              if ((value as number) >= 0) {
                setAfterCount(value as number);
              }
            }}
          />
          { hwType==="VS" &&
            <Select 
              mode="tags"
              value={afterSerialNumbers}
              style={{ width: '100%' }}
              onChange={handleAfterSerialNumbers}
              maxTagCount={afterCount}
              placeholder="Please select"
            >
              {afterSerialNumbers.map((serialNumber: string) => (
                <Option key={serialNumber} value={serialNumber}>
                  {serialNumber}
                </Option>
              ))}
            </Select>
          }
        </Form.Item>

      </Form>
    </div>
  );
};

export default ScannerInfo;
