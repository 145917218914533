import { Form, Select, Switch } from "antd";
import { DefaultOptionType } from "antd/es/select";

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

export interface EndPointInfo {
  call_endpoint: number;
  inference_ip: string;
}

function EndPoint(props: any) {
  const urls: DefaultOptionType[] = [
    {
      value: "lemon.nuvi-lab.com",
      label: "lemon.nuvi-lab.com",
    },
    {
      value: "lemon-a-dev.nuvi-lab.com",
      label: "lemon-a-dev.nuvi-lab.com",
    },
  ];

  const handleSwitch = (checked: boolean) => {
    props.setInfo({ ...props.info, call_endpoint: checked ? 1 : 0 });
  };

  const handleChange = (value: string) => {
    props.setInfo({ ...props.info, inference_ip: value });
  };

  return (
    <div>
      <h2>EndPoint</h2>
      <Form.Item label="실시간 분석" valuePropName="is_call_endpoint">
        <Switch checked={props.info.call_endpoint === 1} onChange={handleSwitch} />
      </Form.Item>
      <Form.Item label="실시간 분석" valuePropName="is_call_endpoint">
        <Select
          disabled={props.info.call_endpoint === 0}
          value={props.info.inference_ip}
          onChange={handleChange}
          options={urls}
        ></Select>
      </Form.Item>
    </div>
  );
};

export default EndPoint;
