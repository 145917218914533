import { DatePicker, Form, Radio, TimePicker } from "antd";
import dayjs, {Dayjs} from "dayjs";
import { useEffect, useState } from "react";
// import axios from "axios";
import axiosInstance from "../common/AxiosInstance";
import "@/pages/GeneralPage.scss"

import { useRecoilState } from "recoil";
import {
  serviceTypeIdState,
  startDateState,
  endDateState,
  startBTimeState,
  endBTimeState,
  startLTimeState,
  endLTimeState,
  startDTimeState,
  endDTimeState,
  startNTimeState,
  endNTimeState,
  preStartDateState,
  baTypeState,
  mainState
} from "@/components/department/recoil/departmentAtom";

const { RangePicker } = DatePicker;

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

interface Service{
  id: number;
  name: string;
}

function DepartmentOperation() {
  const [serviceTypeId, setServiceTypeId] = useRecoilState(serviceTypeIdState);
  const [preStartDate, setPreStartDate] = useRecoilState(preStartDateState);
  const [startDate, setStartDate] = useRecoilState(startDateState);
  const [endDate, setEndDate] = useRecoilState(endDateState);
  const [startBTime, setStartBTime] = useRecoilState(startBTimeState);
  const [endBTime, setEndBTime] = useRecoilState(endBTimeState);
  const [startLTime, setStartLTime] = useRecoilState(startLTimeState);
  const [endLTime, setEndLTime] = useRecoilState(endLTimeState);
  const [startDTime, setStartDTime] = useRecoilState(startDTimeState);
  const [endDTime, setEndDTime] = useRecoilState(endDTimeState);
  const [startNTime, setStartNTime] = useRecoilState(startNTimeState);
  const [endNTime, setEndNTime] = useRecoilState(endNTimeState);
  const [baType, setBAType] = useRecoilState(baTypeState);
  const [main, ] = useRecoilState(mainState);

  const [services, setServices] = useState<Service[]>([]);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const { data } = await axiosInstance.get("/api/department/service");
        setServices(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchService();
  }, []);

  const time_format = "HH:mm";

  const onPreDateChange = (_: any, dateStrings: string) => {
    if (dateStrings === null || dateStrings === "")
      setPreStartDate(dateStrings);
    else if (startDate === null || startDate === "")
      setPreStartDate(dateStrings);
    else if (dateStrings < startDate)
      setPreStartDate(dateStrings);
    else
      alert("Pre Start Date should be earlier than Start Date");
  };

  
  const onDateChange = (
    _: RangeValue<Dayjs>, dateStrings: [string, string]) => {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
  };

  const onBChange = (
    _: RangeValue<Dayjs>, timeString: [string, string]) => {
      setStartBTime(timeString[0]);
      setEndBTime(timeString[1]);
  };
  
  const onLChange = (
    _: RangeValue<Dayjs>, timeString: [string, string]) => {
      setStartLTime(timeString[0]);
      setEndLTime(timeString[1]);
  };

  const onDChange = (
    _: RangeValue<Dayjs>, timeString: [string, string]) => {
      setStartDTime(timeString[0]);
      setEndDTime(timeString[1]);
  };

  const onNChange = (
    _: RangeValue<Dayjs>, timeString: [string, string]) => {
      setStartNTime(timeString[0]);
      setEndNTime(timeString[1]);
  };

  return (
    <div>
      <h2>Department Operations</h2>
      <Form>
        <Form.Item 
          className="custom-red-label"
          label="Service Type">
          <Radio.Group value={serviceTypeId} onChange={(e) => setServiceTypeId(e.target.value)}>
            {services.map((service: any) => {
              return (
                <Radio.Button key={service.name} value={service.id}>
                  {service.name}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <h3>BA Type</h3>
        <Form.Item 
          className={ main === 2 ? "custom-red-label": "" }
          label="BA Type">
          <Radio.Group value={baType} onChange={(e) => setBAType(e.target.value)}>
            <Radio.Button key={"BOTH"} value={"BOTH"}>
              {"BOTH"}
            </Radio.Button>
            <Radio.Button key={"AFTER"} value={"AFTER"}>
              {"AFTER"}
            </Radio.Button>
            <Radio.Button key={"BEFORE"} value={"BEFORE"}>
              {"BEFORE"}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <h3>Pre Operate Start Date</h3>
        <Form.Item
          className={ main === 3 ? "custom-red-label": "" }
          label="pre Start Date"
        >
          <DatePicker 
            value={preStartDate ? dayjs(preStartDate) : null}
            onChange={onPreDateChange}
          />
        </Form.Item>
        <h3>Operating Date</h3>
        <Form.Item>
          <RangePicker 
            allowEmpty={[true, true]}
            value={[startDate ? dayjs(startDate) : null,
                     endDate ? dayjs(endDate) : null]}
            onChange={onDateChange}
          />
        </Form.Item>
        <h3>Operating Time</h3>
        <Form.Item label="B">
          <TimePicker.RangePicker
            value={[startBTime ? dayjs(startBTime, time_format) : null,
                     endBTime ? dayjs(endBTime, time_format) : null]}
            format={time_format}
            onChange={onBChange}
          />
        </Form.Item>
        <Form.Item label="L">
          <TimePicker.RangePicker
            value={[startLTime ? dayjs(startLTime, time_format) : null, 
                            endLTime ? dayjs(endLTime, time_format) : null]}
            format={time_format}
            onChange={onLChange}
          />
        </Form.Item>
        <Form.Item label="D">
          <TimePicker.RangePicker
            value={[startDTime ? dayjs(startDTime, time_format) : null, 
                    endDTime ? dayjs(endDTime, time_format) : null]}
            format={time_format}
            onChange={onDChange}
          />
        </Form.Item>
        <Form.Item label="N">
          <TimePicker.RangePicker
            value={[startNTime ? dayjs(startNTime, time_format) : null, 
                    endNTime ? dayjs(endNTime, time_format) : null]}
            format={time_format}
            onChange={onNChange}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default DepartmentOperation;
