import { Form, InputNumber } from "antd";
import React from "react";

export interface CameraInfo {
  roi_xmax: number;
  roi_xmin: number;
  roi_ymax: number;
  roi_ymin: number;
  resolution_h: number;
  resolution_w: number;
}

interface Props {
  info: CameraInfo;
  setInfo: React.Dispatch<React.SetStateAction<CameraInfo>>;
}

const CameraCommon: React.FC<Props> = ({ info, setInfo }) => {
  const min = 1;
  const max = 2000;

  return (
    <div>
      <Form.Item label="resolution_w">
        <InputNumber
          min={min}
          max={max}
          value={info.resolution_w}
          onChange={(v) => setInfo({ ...info, resolution_w: v as number })}
        />
      </Form.Item>
      <Form.Item label="resolution_h">
        <InputNumber
          min={min}
          max={max}
          value={info.resolution_h}
          onChange={(v) => setInfo({ ...info, resolution_h: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_xmin">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_xmin}
          onChange={(v) => setInfo({ ...info, roi_xmin: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_ymin">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_ymin}
          onChange={(v) => setInfo({ ...info, roi_ymin: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_xmax">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_xmax}
          onChange={(v) => setInfo({ ...info, roi_xmax: v as number })}
        />
      </Form.Item>
      <Form.Item label="roi_ymax">
        <InputNumber
          min={min}
          max={max}
          value={info.roi_ymax}
          onChange={(v) => setInfo({ ...info, roi_ymax: v as number })}
        />
      </Form.Item>
    </div>
  );
};

export default CameraCommon;
