import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import {RecoilRoot} from "recoil";

const { Header, Sider, Content } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <RecoilRoot>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu theme="dark" mode="inline">
            <Menu.Item key="1">
              <Link to="/department">
                <span>기관 등록/수정</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/scanner">
                <span>스캐너 등록</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/user">
                <span>유저 등록</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/setting">
                <span>스캐너 셋팅 수정</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/login">
                <span>로그인</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="https://www.notion.so/3db37148c2ac4ddd87be8fcedd7637d9">
                <span>도움말</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header style={{ padding: 0, background: colorBgContainer }}>
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </RecoilRoot>
  );
};

export default App;
