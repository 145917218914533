import ReactDOM from "react-dom/client";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import App from "@/App";
// import Home from "@/pages/Home";
import LoginPage from "./pages/LoginPage";
import ErrorPage from "@/pages/ErrorPage";
import DepartmentPage from "./pages/DepartmentPage";
import ScannerPage from "./pages/ScannerPage";
import UserPage from "./pages/UserPage";
import ScannerSettingPage from "./pages/ScannerSettingPage";
import React from "react";
import { 
  useLocation, 
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes 
} from "react-router-dom";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.VITE_APP_ENV,
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <LoginPage />,
        // loader
      },
      {
        path: "/department",
        element: <DepartmentPage />,
        loader: async () => {
          if (window.localStorage.getItem("access_token") === null) {
            window.location.replace("/login");
            return false
          }
          return true;
        },
      },
      {
        path: "/scanner",
        element: <ScannerPage />,
        loader: async () => {
          if (window.localStorage.getItem("access_token") === null) {
            window.location.replace("/login");
            return false
          }
          return true;
        },
      },
      {
        path: "/user",
        element: <UserPage />,
        loader: async () => {
          if (window.localStorage.getItem("access_token") === null) {
            window.location.replace("/login");
            return false
          }
          return true;
        },
      },
      {
        path: "/setting",
        element: <ScannerSettingPage />,
        loader: async () => {
          if (window.localStorage.getItem("access_token") === null) {
            window.location.replace("/login");
            return false
          }
          return true;
        },
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);


ReactDOM.createRoot(document.getElementById("root")!).render(
  
  <RouterProvider router={router} />
  // <React.StrictMode>
  // </React.StrictMode>
);
