import axios from "axios";
import { showModalMovePage } from "@/utils/Modal";

// const jwt = require('jsonwebtoken');
const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = import.meta.env.VITE_APP_API_URL as string;
// axiosInstance.defaults.baseURL = "https://register-tool-backend.nuvi-lab.com",
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(
  (config) => {
    // console.log(config)
    const access_token = window.localStorage.getItem('access_token');
    config.headers.Authorization = `Bearer ${access_token}`;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (err) {
    // const originalConfig = err.config;
    if (err.response && err.response.data.detail === "Expired token"){
      try {
        const data = {
          login_id: window.localStorage.getItem('login_id'),
          access_token: window.localStorage.getItem('access_token'),
          refresh_token: window.localStorage.getItem('refresh_token')
        };

        axiosInstance.post("/api/auth/refresh",  data)
        .then(response => {
          const newAccessToken = response.data.access_token;
          window.localStorage.setItem("access_token", newAccessToken);
          response.headers.Authorization = `Bearer ${newAccessToken}`;
          window.location.reload();
        })
        .catch(error => {
          if (error.response.data.detail === "Expired refresh token" || error.response.data.detail === "Invalid refresh credentials"){
            window.localStorage.removeItem('login_id'),
            window.localStorage.removeItem('access_token'),
            window.localStorage.removeItem('refresh_token'),
            showModalMovePage(false, "로그인 시간 만료", "", "/login");
          }
        });
      } catch (e) {
        console.log(e)
      }
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
)

export default axiosInstance;