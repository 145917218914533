import { atom } from "recoil";

interface Department {
  id: number
  name: string;
  name_eng: string;
  type: string;
  education_office: string;
  department_main_category_id: number;
  department_sub_category_id: number;
}

interface Category {
  main_category_id: number;
  main_name: string;
  sub_category_id: number;
  sub_name: string;
}

export const departmentsState = atom<Department[]>({
  key: 'departmentsState',
  default: [],
});

// Categories Atom
export const categoriesState = atom<Category[]>({
  key: 'categoriesState',
  default: [],
});

export const mainState = atom<number>({
  key: 'mainState',
  default: 1,
});

export const subState = atom<number | undefined>({
  key: 'subState',
  default: undefined,
});

export const typeState = atom<string | null>({
  key: 'typeState',
  default: null,
});

// Is Office Disabled Atom
export const isOfficeDisabledState = atom<boolean>({
  key: 'isOfficeDisabledState',
  default: true,
});

// Department Name Atom
export const dpmtNameState = atom<string>({
  key: 'dpmtNameState',
  default: "",
});

// Department Name English Atom
export const dpmtNameEngState = atom<string>({
  key: 'dpmtNameEngState',
  default: "",
});

// Valid Name Atom
export const isValidNameState = atom<number>({
  key: 'isValidNameState',
  default: 0,
});

// Valid English Name Atom
export const isValidNameEngState = atom<number>({
  key: 'isValidNameEngState',
  default: 0,
});

// Meal Count Atom
export const mealCountState = atom<number | undefined>({
  key: 'mealCountState',
  default: undefined,
});

// Invalid Meal Count Atom
export const invalidMealCountState = atom<boolean>({
  key: 'invalidMealCountState',
  default: true,
});

// Offices Atom
export const officesState = atom<string[]>({
  key: 'officesState',
  default: [
    "서울특별시교육청",
    "부산광역시교육청",
    "대구광역시교육청",
    "인천광역시교육청",
    "광주광역시교육청",
    "대전광역시교육청",
    "울산광역시교육청",
    "세종특별자치시교육청",
    "경기도교육청",
    "강원특별자치도교육청",
    "충청북도교육청",
    "충청남도교육청",
    "전라북도교육청",
    "전라남도교육청",
    "경상북도교육청",
    "경상남도교육청",
    "제주특별자치도교육청",
  ],
});

// Selected Office Atom
export const officeState = atom<string>({
  key: 'officeState',
  default: "서울특별시교육청",
});

// Department Establishment Category ID Atom
export const dpmtEstablishmentCategoryIdState = atom<number | undefined>({
  key: 'dpmtEstablishmentCategoryIdState',
  default: undefined,
});

// Department Name Atom
export const dpmtEstablishmentCategoryState = atom<string| undefined>({
  key: 'dpmtEstablishmentCategoryState',
  default: "",
});

// Service Type ID Atom
export const serviceTypeIdState = atom<number | undefined>({
  key: 'serviceTypeIdState',
  default: undefined,
});

// Start Date Atom
const today = new Date();
const formattedToday: string = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

export const preStartDateState = atom<string| null>({
  key: 'preStartDateState',
  default: null,
});

export const startDateState = atom<string| null>({
  key: 'startDateState',
  default: formattedToday,
});

// End Date Atom
export const endDateState = atom<string | null>({
  key: 'endDateState',
  default: null,
});

// Start Breakfast Time Atom
export const startBTimeState = atom<string>({
  key: 'startBTimeState',
  default: "",
});

// End Breakfast Time Atom
export const endBTimeState = atom<string>({
  key: 'endBTimeState',
  default: "",
});

// Start Lunch Time Atom
export const startLTimeState = atom<string>({
  key: 'startLTimeState',
  default: "11:00",
});

// End Lunch Time Atom
export const endLTimeState = atom<string>({
  key: 'endLTimeState',
  default: "14:00",
});

// Start Dinner Time Atom
export const startDTimeState = atom<string>({
  key: 'startDTimeState',
  default: "",
});

// End Dinner Time Atom
export const endDTimeState = atom<string>({
  key: 'endDTimeState',
  default: "",
});

// Start Night Time Atom
export const startNTimeState = atom<string>({
  key: 'startNTimeState',
  default: "",
});

// End Night Time Atom
export const endNTimeState = atom<string>({
  key: 'endNTimeState',
  default: "",
});

// Dashboard Option Atom
export const dashboardOptionState = atom<string>({
  key: 'dashboardOptionState',
  default: "",
});

// User Name Atom
export const userNameState = atom<string>({
  key: 'userNameState',
  default: "",
});

// Email Address Atom
export const emailAddressState = atom<string>({
  key: 'emailAddressState',
  default: "",
});

// Phone Number Atom
export const phoneNumberState = atom<string>({
  key: 'phoneNumberState',
  default: "",
});

// Country ID Atom
export const countryIdState = atom<number | null>({
  key: 'countryIdState',
  default: null,
});

// State ID Atom
export const stateIdState = atom<number | null>({
  key: 'stateIdState',
  default: null,
});

// City ID Atom
export const cityIdState = atom<number | null>({
  key: 'cityIdState',
  default: null,
});

// District ID Atom
export const districtIdState = atom<number | null>({
  key: 'districtIdState',
  default: null,
});

// // Country ID Atom
// export const countryState = atom<string| undefined>({
//   key: 'countryState',
//   default: undefined,
// });

// // State ID Atom
// export const stateState = atom<string| undefined>({
//   key: 'stateState',
//   default: undefined,
// });

// // City ID Atom
// export const cityState = atom<string| undefined>({
//   key: 'cityState',
//   default: undefined,
// });

// // District ID Atom
// export const districtState = atom<string| undefined>({
//   key: 'districtState',
//   default: undefined,
// });


// Address Line Atom
export const roadAddressState = atom<string>({
  key: 'roadAddressState',
  default: "",
});

export const buildingAddressState = atom<string>({
  key: 'buildingAddressState',
  default: "",
});

export const buttonClickedState = atom<boolean>({
  key: 'buttonClickedState',
  default: false,
})

export const baTypeState = atom<string>({
  key: 'baTypeState',
  default: "BOTH",
});