import { CameraInfo } from "@/components/setting/CameraCommon";
import { DepthInfo } from "@/components/setting/DepthDetector";
import EndPoint, { EndPointInfo } from "@/components/setting/EndPoint";
import Face, { FaceInfo } from "@/components/setting/Face";
import Realsense from "@/components/setting/Realsense";
import Time, { TimeInfo } from "@/components/setting/Time";
import Mode, { ModeInfo } from "@/components/setting/Mode";
import BA, { BAInfo } from "@/components/setting/BA";
import UI, { UIInfo } from "@/components/setting/UI";
import Save, { SaveInfo } from "@/components/setting/Save";


import { Cascader, Button } from "antd";
import type { DefaultOptionType } from 'antd/es/cascader';
// import axios from "axios";
import axiosInstance from "@/components/common/AxiosInstance";
import { useEffect, useState } from "react";
import "./GeneralPage.scss"
import { showModal } from "@/utils/Modal";

interface Option {
  value: number;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
}

function ScannerSettingPage() {
  const [scannerID, setScannerID] = useState<number | string>();
  const [serialNumber, setSerialNumber] = useState<string>();
  const [options, setOptions] = useState<Option[]>([]);
  const [realsenseInfo, setRealsenseInfo] = useState<CameraInfo>({
    roi_xmax: 0,
    roi_xmin: 0,
    roi_ymax: 0,
    roi_ymin: 0,
    resolution_h: 0,
    resolution_w: 0,
  });

  const [faceInfo, setFaceInfo] = useState<FaceInfo>({
    roi_xmax: 960, // 얼굴 인식 카메라에서 던져주는 사진을 화면에 잘라서 표출시 사용
    roi_xmin: 0,
    roi_ymax: 1062,
    roi_ymin: 200,
    face_view: 1, // 얼굴 인식 화면 보여줄지 말지 옛날 여자고등학교에서 사용
    resolution_h: 1280, // 카메라에서 던져주는 사진 해상도
    resolution_w: 720,
    min_face_size: 0, // 얼굴의 최소 픽셀 크기
    delay_to_look_front: 0.3,
    sensetime_local_db_sync: 1,
  });

  const [depthInfo, setDepthInfo] = useState<DepthInfo>({
    iter_to_init: 0,
    top_area_ymax: 260,
    top_area_ymin: 200,
    left_area_xmax: 590,
    left_area_xmin: 520,
    plate_depth_max: 1000,
    plate_depth_min: 200,
    point_max_count: 5,
    point_min_count: 5,
    right_area_xmax: 860,
    right_area_xmin: 790,
    bottom_area_ymax: 380,
    bottom_area_ymin: 320,
    depth_change_min: 20,
    turn_off_delay_sec: 1,
    check_value_for_reset: 20,
    min_count_for_stability: 0,
  });

  const [timeInfo, setTimeInfo] = useState<TimeInfo>({
    bld_division:{
      bl: "00:00",
      dn: "00:00",
      ld: "00:00",
    },
    offtime: "00:00",
    use_off_func: 1,
  });

  const [endpointInfo, setEndpointInfo] = useState<EndPointInfo>({
    call_endpoint: 0,
    inference_ip: "",
  });

  const [modeInfo, setModeInfo] = useState<ModeInfo>({
    identification: "plate",
    standard_serving_amount: 1,
    enable_edge_ai: false,
  })

  const [baInfo, setBAInfo] = useState<BAInfo>({
    ba:"B",
  })

  const [uiInfo, setUIInfo] = useState<UIInfo>({
    eng_ui: 0,
    ui_mode: 0,
    display_point: 0,
    play_ai_voice: 0,
    gif_split_mode: 0,
    result_to_init_delay: 0,
    identification_to_init_delay: 0,
    waiting_face_gt_to_init_delay: 0,
  })

  const [saveInfo, setSaveInfo] = useState<SaveInfo>({
    compress_data: 0,
  })

  useEffect(() => {
    const fetchScanner = async () => {
      try {
        const { data } = await axiosInstance.get("/api/scanner");
        
        const departmentMap: Record<string, typeof data> = {} // 부서별 데이터를 저장할 객체

        data.forEach((item: any) => {
          const departmentName = item.department_name;
          if (!departmentMap[departmentName]) {
            departmentMap[departmentName] = [];
          }
          departmentMap[departmentName].push(item);
        });
        
        // Cascader 옵션으로 변환
        const cascaderOptions = Object.keys(departmentMap).map(departmentName => ({
          label: departmentName,
          value: departmentMap[departmentName].map((item: any) => item.department_id)[0], // department_id를 value로 사용
          isLeaf: false,
          children: departmentMap[departmentName].map((item: any) => ({
            label: item.serial_number,
            value: item.id,
          })),
        }));

        setOptions(cascaderOptions);
      
      } catch (error) {
        console.log(error);
      }

    };
    fetchScanner();

  }, []);

  const handleChange = async (
    value: (string | number)[],
    selectedOptions: Option[]
  ) => {

    try {
      setScannerID(value[1]);
      setSerialNumber(selectedOptions[1].label);

      const { data } = await axiosInstance.get("/api/scanner/setting/"+value[1]);
      const { ba, ui, mode, save, realsense, face, time, endpoint } = data;
      const { depth_detector } = realsense;
      
      setRealsenseInfo(realsense);
      setFaceInfo(face);
      setDepthInfo(depth_detector);
      setEndpointInfo(endpoint);
      setTimeInfo(time);
      setModeInfo(mode);
      setBAInfo(ba);
      setUIInfo(ui);
      setSaveInfo(save);
    } catch (error) {
      console.log("error:", error, value[1]);
    }
  };

  const onUpdateScannerSetting = () => {
    const data = {
      scanner_id: scannerID,
      scanner_setting: {
        mode: modeInfo,
        ui: uiInfo,
        realsense: {
          ...realsenseInfo,
          depth_detector:depthInfo
        },
        time: timeInfo,
        endpoint:endpointInfo,
        save: saveInfo,
        ba: baInfo,
        scanner: {
          scanner_id: serialNumber
        },
        face: faceInfo,
      }
    }

    const fetchData = async () => {
      try {
        await axiosInstance.patch("/api/scanner/setting", data)
        .then(_ => {
          showModal(true, "Update Success");
        })
        .catch(_ =>{
          showModal(false, "Update Fail");
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }

  const dpmtNameFilter = (inputValue: string, path: DefaultOptionType[]) =>
  path.some(
    (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );

  return (
    <div>
      <h1>Scanner Setting</h1>
      <Cascader 
        showSearch={{filter:dpmtNameFilter}}
        options={options} 
        // loadData={loadData} 
        onChange={handleChange}
      />
      <div className="column-layout">
        <div className="column">
          <Realsense
            realsense={realsenseInfo} setRealsense={setRealsenseInfo}
            depthInfo={depthInfo} setDepthInfo={setDepthInfo}
          />
        </div>
        <div className="column">
          <Face info={faceInfo} setInfo={setFaceInfo} />
        </div>
        <div className="column">
          <EndPoint info={endpointInfo} setInfo={setEndpointInfo} />
          <Time time={timeInfo} setTime={setTimeInfo} />
          <Mode info={modeInfo} setInfo={setModeInfo} />
        </div>
        <div className="column">
          <BA info={baInfo} setInfo={setBAInfo} />
          <UI info={uiInfo} setInfo={setUIInfo} />
          <Save info={saveInfo} setInfo={setSaveInfo} />
        </div>
        
      </div>
      <Button onClick={onUpdateScannerSetting}>send</Button>
    </div>
  );
};

export default ScannerSettingPage;
