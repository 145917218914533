import { Form, Select, Input, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
// import Search from "antd/es/input/Search";
// import axios from "axios";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form"
import axiosInstance from "../common/AxiosInstance";
import "@/pages/GeneralPage.scss"

import { useRecoilState } from "recoil";

import {
  mainState,
  countryIdState,
  stateIdState,
  cityIdState,
  districtIdState,
  roadAddressState,
  buildingAddressState,
  buttonClickedState,
  // countryState,
  // stateState,
  // cityState,
  // districtState
} from "@/components/department/recoil/departmentAtom";

interface Country {
  id: number;
  name: string;
  code: string;
  number: string;
}

interface State {
  id: number;
  name: string;
  country_id: number
}

interface City {
    id: number;
    name: string;
    state_id: number;
    grid_x: number;
    grid_y: number;
  }

interface District {
    id: number;
    city_id: number;
    city_name: string;
    name: string;
    grid_x: number;
    grid_y: number;
}

function Address(_: any) {
  const [countryId, setCountryId] = useRecoilState(countryIdState);
  const [stateId, setStateId] = useRecoilState(stateIdState);
  const [cityId, setCityId] = useRecoilState(cityIdState);
  const [districtId, setDistrictId] = useRecoilState(districtIdState);
  const [roadAddress, setRoadAddress] = useRecoilState(roadAddressState);
  const [buildingAddress, setBuildingAddress] = useRecoilState(buildingAddressState);
  const [main, ] = useRecoilState(mainState);
  const [buttonClicked, setButtonClicked] = useRecoilState(buttonClickedState);

  const [selectCountry, setCountry] = useState<string>()
  const [selectState, setState] = useState<string>()
  const [selectCity, setCity] = useState<string>()
  const [selectDistrict, setDistrict] = useState<string>()

  const [addressType, setAddressType] = useState(1);

  const [countries, setCountries] = useState<Country[]>()
  const [states, setStates] = useState<State[]>()
  const [cities, setCities] = useState<City[]>()
  const [districts, setDistricts] = useState<District[]>()
  const { control, setValue } = useForm({mode:"onChange"});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get("/api/department/country");
        setCountries(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (buttonClicked === true){
      let stateList;
      let cityList;
      let districtList;
      if (countryId){
        stateList = getStatesApi(countryId);
      }
      else{
        setState(undefined);
      }
      if (stateId){
        cityList = getCityApi(stateId);
      }
      else{
        setCity(undefined);
      }
      if (cityId){
        districtList = getDistrictApi(cityId);
      }
      else{
        setDistrict(undefined)
      }
      
      setCountry(countries ? countries.filter((item: any) => item.id === countryId)[0]?.name : undefined);
      stateList?.then((data: any) => {
        setState(data ? data.filter((item: any) => item.id === stateId)[0]?.name : undefined);
      })
      cityList?.then((data: any) => {
        setCity(data ? data.filter((item: any) => item.id === cityId)[0]?.name : undefined);
      })
      districtList?.then((data: any) => {
        setDistrict(data ? data.filter((item: any) => item.id === districtId)[0]?.name : undefined);
      })
      
      setButtonClicked(false);
    }
  }, [buttonClicked]);

  useEffect(() => {
    let districtList;
    if (cityId){
      districtList = getDistrictApi(cityId);
    }
    else{
      setDistrict(undefined)
    }

    districtList?.then((data: any) => {
      setDistrict(data ? data.filter((item: any) => item.id === districtId)[0]?.name : undefined);
    })
  }, [main]);

  useEffect(() => {
    setValue("road_address", roadAddress);
    setValue("building_address", buildingAddress);
  }, [roadAddress, buildingAddress]);


  const filterOption = (input: string, option: any) => {
    return (option.value ?? '').toLowerCase().includes(input.toLowerCase());
  }

  const getStatesApi = (countryId: number) => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get("/api/department/state/"+countryId);
        setStates(data);
        return data
      } catch (e) {
        console.log(e);
      }
    };
    return fetchData();
  }

  const getCityApi = (stateId: number) => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get("/api/department/city/"+stateId);
        setCities(data);
        return data
      } catch (e) {
        console.log(e);
      }
    };
    return fetchData();
  }

  const getDistrictApi = (cityId: number) => {
    const fetchData = async () => {
      try {
        if (main === 2){
          const { data } = await axiosInstance.get("/api/department/district/"+cityId+"?main_category=2");
          setDistricts(data);
          return data
        }
        else{
          const { data } = await axiosInstance.get("/api/department/district/"+cityId+"?main_category=3");
          setDistricts(data);
          return data
        }
      } catch (e) {
        console.log(e);
      }
    };
    return fetchData();
  }


  const handleCountry = (value: string, option: any) => {
    setCountry(value);
    setState(undefined);
    setCity(undefined);
    setDistrict(undefined);
    
    setCountryId(option.key);
    setStateId(null);
    setCityId(null);
    setDistrictId(null);

    getStatesApi(option.key);
    setCities([]);
    setDistricts([]);
  }
  
  const handleState = (value: string, option: any) => {
    setState(value);
    setCity(undefined);
    setDistrict(undefined);

    setStateId(option.key);
    setCityId(null);
    setDistrictId(null);

    getCityApi(option.key)
    setDistricts([]);
  }
  
  const handleCity = (value: string, option: any) => {
    setCity(value);
    setDistrict(undefined);
    
    setCityId(option.key);
    setDistrictId(null);

    getDistrictApi(option.key)
  }

  const handleDistrict = (value: string, option: any) => {
    setDistrict(value);
    
    setDistrictId(option.key);
  }

  const onAddressTypeChange = (e: RadioChangeEvent) => {
    setAddressType(e.target.value);
  };

  return (
    <div>
      <Form>
        <h2>Address</h2>
        <Form.Item 
          className="custom-red-label"
          label="국가">
          <Select
            value={selectCountry}
            showSearch
            placeholder="Select a Country name"
            optionFilterProp="children"
            onChange={handleCountry}
            filterOption={filterOption}
          >
            {countries?.map((country: Country) => {
              return (
                <Select.Option key={country.id} value={country.name}>
                  {country.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item 
          className="custom-red-label"
          label="시/도/주">
          <Select
            value={selectState}
            showSearch
            placeholder="Select a State name"
            optionFilterProp="children"
            onChange={handleState}
            filterOption={filterOption}
          >
            {states?.map((state: State) => {
              return (
                <Select.Option key={state.id} value={state.name}>
                  {state.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item 
          label="시/도시"
          className={main===3? "custom-red-label":""}>
          <Select
            value={selectCity}
            showSearch
            placeholder="Select a City name"
            optionFilterProp="children"
            onChange={handleCity}
            filterOption={filterOption}
          >
            {cities?.map((city: City) => {
              return (
                <Select.Option key={city.id} value={city.name}>
                  {city.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item 
          label="읍/면/동">
          <Select
            value={selectDistrict}
            showSearch
            placeholder="Select a District name"
            optionFilterProp="children"
            onChange={handleDistrict}
            filterOption={filterOption}
          >
            {districts?.map((district: District) => {
              return (
                <Select.Option key={district.id} value={district.name}>
                  {district.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>
      
      <Form
        layout="vertical">
        <Form.Item>
          <Radio.Group onChange={onAddressTypeChange} value={addressType}>
            <Radio value={1}>도로명주소</Radio>
            <Radio value={2}>지번</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          { addressType === 1 &&
            <Controller
              name="road_address"
              control={control}
              defaultValue={roadAddress}
              rules={{ 
                required: '도로명 주소를 입력하세요.',
                pattern: {
                  value: /^[가-힣a-zA-Z0-9 -.]*$/,
                  message: '한글,영어,숫자,-. 입력하세요.', 
                },
              }}
              render={({ field, fieldState }) => (
                <Form.Item
                  label="도로명 + 건물번호"
                  hasFeedback
                  validateStatus={fieldState.invalid ? 'error' : ''}
                  help={fieldState.invalid ? fieldState?.error?.message : null}
                  className={main===3? "custom-red-label":""}
                >
                  <Input 
                    {...field} 
                    placeholder="road_address"
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setRoadAddress(e.target.value);
                    }}/>
                </Form.Item>
              )}
            />
          }
          <Controller
            name="building_address"
            control={control}
            defaultValue={buildingAddress}
            rules={{ 
              required: '지번 주소를 입력하세요.',
              pattern: {
                value: /^[가-힣a-zA-Z0-9 -.]*$/,
                message: '한글,영어,숫자,-. 입력하세요.', 
              },
            }}
            render={({ field, fieldState }) => (
              <Form.Item
                label={addressType === 1 ? "건물명" : "지번 및 기타 주소"}
                hasFeedback
                validateStatus={fieldState.invalid ? 'error' : ''}
                help={fieldState.invalid ? fieldState?.error?.message : null}
              >
                <Input 
                  {...field} 
                  placeholder="building_address"
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setBuildingAddress(e.target.value);
                  }}/>
              </Form.Item>
            )}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Address;
