import { Form, InputNumber } from "antd";

export interface UIInfo{
    eng_ui: number,
    ui_mode: number,
    display_point: number,
    play_ai_voice: number,
    gif_split_mode: number,
    result_to_init_delay: number,
    identification_to_init_delay: number,
    waiting_face_gt_to_init_delay: number
}

function UI(props: any) {
  return (
    <>
      <h2>UI</h2>
      <Form>
        <Form.Item label="eng_ui">
            <InputNumber
                value={props.info.eng_ui}
                onChange={(v) => props.setInfo({ ...props.info, eng_ui: v as number })}
            />
        </Form.Item>
        <Form.Item label="ui_mode">
            <InputNumber
                value={props.info.ui_mode}
                onChange={(v) => props.setInfo({ ...props.info, ui_mode: v as number })}
            />
        </Form.Item>
        <Form.Item label="display_point">
            <InputNumber
                value={props.info.display_point}
                onChange={(v) => props.setInfo({ ...props.info, display_point: v as number })}
            />
        </Form.Item>
        <Form.Item label="play_ai_voice">
            <InputNumber
                value={props.info.play_ai_voice}
                onChange={(v) => props.setInfo({ ...props.info, play_ai_voice: v as number })}
            />
        </Form.Item>
        <Form.Item label="gif_split_mode">
            <InputNumber
                value={props.info.gif_split_mode}
                onChange={(v) => props.setInfo({ ...props.info, gif_split_mode: v as number })}
            />
        </Form.Item>
        <Form.Item label="result_to_init_delay">
            <InputNumber
                value={props.info.result_to_init_delay}
                onChange={(v) => props.setInfo({ ...props.info, result_to_init_delay: v as number })}
            />
        </Form.Item>
        <Form.Item label="identification_to_init_delay">
            <InputNumber
                value={props.info.identification_to_init_delay}
                onChange={(v) => props.setInfo({ ...props.info, identification_to_init_delay: v as number })}
            />
        </Form.Item>
        <Form.Item label="waiting_face_gt_to_init_delay">
            <InputNumber
                value={props.info.waiting_face_gt_to_init_delay}
                onChange={(v) => props.setInfo({ ...props.info, waiting_face_gt_to_init_delay: v as number })}
            />
        </Form.Item>
      </Form>
    </>
  );
};

export default UI;