import { Form, Switch, TimePicker } from "antd";
import dayjs from "dayjs";

export interface BLDDivision {
  bl: string;
  dn: string;
  ld: string;
}

export interface TimeInfo {
  bld_division: BLDDivision;
  offtime: string;
  use_off_func: 0 | 1;
}

function Time(props: any) {
  const time_format = "HH:mm";

  const onFixUseOffFunc = (checked: boolean) => {
    props.setTime({...props.time, 
                    use_off_func:checked===true ? 1 : 0})
  }

  return (
    <div>
      <h2>time</h2>
      <Form.Item label="bl">
        <TimePicker
          value={dayjs(props.time.bld_division.bl, time_format)}
          // defaultValue={dayjs(props.time.bld_division.bl, time_format)}
          format={time_format}
          onChange={(_, dateString) => props.setTime({...props.time,
                                              bld_division: {
                                                ...props.time.bld_division,
                                                bl: dateString
                                          }})}
        />
      </Form.Item>
      <Form.Item label="ld">
        <TimePicker
          value={dayjs(props.time.bld_division.ld, time_format)}
          // defaultValue={dayjs(props.time.bld_division.ld, time_format)}
          format={time_format}
          onChange={(_, dateString) => props.setTime({...props.time,
                                              bld_division: {
                                                ...props.time.bld_division,
                                                ld: dateString
                                          }})}
        />
      </Form.Item>
      <Form.Item label="dn">
        <TimePicker
          value={dayjs(props.time.bld_division.dn, time_format)}
          // defaultValue={dayjs(props.time.bld_division.dn, time_format)}
          format={time_format}
          onChange={(_, dateString) => props.setTime({...props.time,
                                              bld_division: {
                                                ...props.time.bld_division,
                                                dn: dateString
                                          }})}
        />
      </Form.Item>
      <Form.Item label="offtime">
        <TimePicker
          value={dayjs(props.time.offtime, time_format)}
          // defaultValue={dayjs(props.time.offtime, time_format)}
          format={time_format}
          onChange={(_, dateString) => props.setTime({ ...props.time, offtime: dateString})}
        />
      </Form.Item>
      <Form.Item label="use_off_func" valuePropName="checked">
        <Switch 
          checked={
            props.time.use_off_func===1 ? true: false}
          onChange={checked => onFixUseOffFunc(checked)}/>
      </Form.Item>
    </div>
  );
};

export default Time;
