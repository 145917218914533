

export const makeDatetimeStrToTime = (datetime: string|null) => {
  const date = datetime? new Date(datetime): null;
  const date_str = `${date?.getHours()}:${date?.getMinutes()}`;
  
  return date_str
};

export function formatTime(inputTime: string| null): string {
  if (inputTime === null || inputTime.length !== 4) {
    return ""; // 유효하지 않은 형식이면 null 반환
  }

  const hours = inputTime.substr(0, 2);
  const minutes = inputTime.substr(2, 2);

  const parsedHours = parseInt(hours, 10);
  const parsedMinutes = parseInt(minutes, 10);

  if (isNaN(parsedHours) || isNaN(parsedMinutes)) {
    return ""; // 파싱 중 오류가 발생하면 null 반환
  }

  if (parsedHours < 0 || parsedHours > 23 || parsedMinutes < 0 || parsedMinutes > 59) {
    return ""; // 유효하지 않은 시간 값이면 null 반환
  }

  const time = new Date();
  time.setHours(parsedHours);
  time.setMinutes(parsedMinutes);
  const date_str = `${time?.getHours()}:${time?.getMinutes()}`;

  return date_str;
}