import { useEffect } from "react";
import { Form, Input } from "antd";
import { useForm, Controller } from "react-hook-form"

import { useRecoilState } from "recoil";
import {
  userNameState,
  emailAddressState,
  phoneNumberState,
  buttonClickedState
} from "@/components/department/recoil/departmentAtom";

interface Admin{
  admin_user_name: string;
  admin_phone_number: string;
  admin_email_address: string;
}

function AdminUser(_: any) {
  const [userName, setUserName] = useRecoilState(userNameState);
  const [emailAddress, setEmailAddress] = useRecoilState(emailAddressState);
  const [phoneNumber, setPhoneNumber] = useRecoilState(phoneNumberState);

  const [buttonClicked, setButtonClicked] = useRecoilState(buttonClickedState);

  const { control, setValue, trigger } = useForm<Admin>({mode:"onChange"});

  useEffect(() => {
    // Recoil에서 가져온 데이터로 필드 값을 설정
    setValue('admin_user_name', userName);
    setValue('admin_email_address', emailAddress);
    setValue('admin_phone_number', phoneNumber);

    if (buttonClicked){
      trigger("admin_user_name");
      trigger("admin_email_address");
      trigger("admin_phone_number");
      setButtonClicked(false)
    }
  }, [userName, emailAddress, phoneNumber]);
  
  const normalizePhoneNumber = (value: string) => {
      // 전화번호에서 하이픈 제거 및 특정 형식으로 변환
    if (value.length>=14)
      return value.slice(0,-1);
    return value.replace(/-/g, '').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  }

  return (
    <div>
      <h2>Admin User</h2>
      <Form>
        <Controller
          name="admin_user_name"
          control={control}
          defaultValue={userName}
          rules={{ 
            required: '이름을 입력하세요.',
            pattern: {
              value: /^[a-zA-Z가-힣]*$/,
              message: '영어,한글만 입력하세요.', 
            },
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label="이름"
              hasFeedback
              validateStatus={fieldState.invalid ? 'error' : ''}
              help={fieldState.invalid ? fieldState?.error?.message : null}
            >
              <Input 
                {...field}
                placeholder="admin_name"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setUserName(e.target.value);
                }}/>  
            </Form.Item>
          )}/>
        <Controller
          name="admin_phone_number"
          control={control}
          defaultValue={phoneNumber}
          rules={{ 
            required: '핸드폰 번호를 입력하세요.',
            pattern: {
              value: /^[0-9-]*$/,
              message: '숫자만 입력하세요.', 
            },
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label="핸드폰 번호"
              hasFeedback
              validateStatus={fieldState.invalid ? 'error' : ''}
              help={fieldState.invalid ? fieldState?.error?.message : null}
            >
              <Input 
                {...field} 
                placeholder="phone_number" 
                onChange={(e) => {
                  const normalizeValue = normalizePhoneNumber(e.target.value);
                  field.onChange(normalizeValue);
                  setPhoneNumber(normalizeValue);
                }}
              />
            </Form.Item>
          )}/>
        <Controller
          name="admin_email_address"
          control={control}
          defaultValue={emailAddress}
          rules={{ 
            required: '이메일을 입력하세요.',
            pattern: {
              value: /^[a-zA-Z0-9@.]*$/,
              message: '이메일 양식에 맞춰 입력하세요.', 
            },
          }}
          render={({ field, fieldState }) => (
            <Form.Item
              label="이메일"
              hasFeedback
              validateStatus={fieldState.invalid ? 'error' : ''}
              help={fieldState.invalid ? fieldState?.error?.message : null}
            >
              <Input 
                {...field} 
                placeholder="email address"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setEmailAddress(e.target.value);
                }}/>
            </Form.Item>
          )}/>
      </Form>
    </div>
  );
};

export default AdminUser;
