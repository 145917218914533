import Login from "@/components/login/Login";
// import { useState } from "react"

function LoginPage() {
  // const [isLogin, setIsLogin] = useState<boolean>(false)
  return (
    <div>
      <Login
        // isLogin={isLogin} setIsLogin={setIsLogin}
      />
    </div>
  );
};

export default LoginPage;