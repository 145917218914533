import { Form, Radio } from "antd";

import { useRecoilState } from "recoil";
import {
  mainState,
  dashboardOptionState,
} from "@/components/department/recoil/departmentAtom";

function DashboardOption(_: any) {
  const [main, ] = useRecoilState(mainState);
  const [dashboardOption, setDashboardOption] = useRecoilState(dashboardOptionState);
    
  return (
    <div>
      <h2>DashBoard Option</h2>
      <Form>
        <Form.Item label="실시간 잔반제로 점수">
          <Radio.Group 
            value={dashboardOption}
            disabled={main === 3 ? true : false} 
            onChange={(e) => setDashboardOption(e.target.value)}>
            <Radio.Button value="0">월별로 보기</Radio.Button>
            <Radio.Button value="1">현재 학기만 보기</Radio.Button>
            <Radio.Button value="2">전체 보기</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DashboardOption;
