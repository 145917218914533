import { useEffect } from "react";
import { Button } from "antd";
// import axios from "axios";

import axiosInstance from "@/components/common/AxiosInstance";

import ScannerInfo from "@/components/scanner/ScannerInfo";
import ScannerSetting from "@/components/scanner/ScannerSetting";
import "./GeneralPage.scss"
import { showModal } from "@/utils/Modal";
import { useLocation } from 'react-router-dom';

import { useRecoilState } from "recoil";
import {
  Department,
  HwType,
  departmentsState,
  departmentIDState,
  hwTypesState,
  hwTypeState,
  beforeScannerCountState,
  afterScannerCountState,
  scannerTypeState,
  uiIdState,
  inferIpState,
  stdVolumeState,
  offTimeState,
  edgeAiState,
  beforeScannerSerialNumberListState,
  afterScannerSerialNumberListState
} from "@/components/scanner/recoil/scannerAtom";


function ScannerPage() {
  const [departments, setDepartments] = useRecoilState(departmentsState);
  const [dpmtId, setDpmtId] = useRecoilState(departmentIDState);
  const [hwTypes, setHwTypes] = useRecoilState(hwTypesState);
  const [hwType, setHwType] = useRecoilState(hwTypeState);
  const [afterCount, setAfterCount] = useRecoilState(afterScannerCountState);
  const [beforeCount, setBeforeCount] = useRecoilState(beforeScannerCountState);
  const [scannerType, ] = useRecoilState(scannerTypeState);
  const [uiId,] = useRecoilState(uiIdState);
  const [inferIp, ] =  useRecoilState(inferIpState);
  const [stdVolume, ] =  useRecoilState(stdVolumeState);
  const [offTime, ] =  useRecoilState(offTimeState);
  const [edgeAI, ] =  useRecoilState(edgeAiState);
  const [beforeSerialNumbers, ] = useRecoilState(beforeScannerSerialNumberListState);
  const [afterSerialNumbers, ] = useRecoilState(afterScannerSerialNumberListState);

  useEffect(()=>{
    const fetchData = async () =>{
      try {
        const { data } = await axiosInstance.get("/api/department");
        setDepartments(data);
      } catch (e) {
        console.log(e);
      }
    };
    const fetchHwType = async () =>{
      try {
        const { data } = await axiosInstance.get("/api/scanner/hw-type");
        setHwTypes(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
    fetchHwType();
  }, [])


  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.size > 0){
      const queryDpmtName = queryParams.get('dpmtName'); 
      departments.forEach((department: Department, _: number) => {
        department.name === queryDpmtName ? setDpmtId(department.id): null;
      })

      const queryHwType = queryParams.get('hwType')?.replace("_", " "); 
      hwTypes.forEach((hwType: HwType, _: number) => {
        hwType.type === queryHwType ? setHwType(hwType.initial): null;
      })

      const queryScannerCount = queryParams.get('scannerCount');
      const scannerCounts = queryScannerCount?.split(",")
      scannerCounts?.forEach((obj: any, _) => {
        if (obj.includes("배식")){
          const count: number = Number(obj?.split("_")[1][0]);
          setBeforeCount(count);
        }
        else if (obj.includes("퇴식")){
          const count: number = Number(obj?.split("_")[1][0]);
          setAfterCount(count);
        }
      });
    }
    
  }, [departments, hwTypes, location]);


  const onSendScannerRecord = () =>{
    const scannerRecordInfo = {
      department_id: dpmtId,
      scanner_count: beforeCount+afterCount,
      before_scanner_count: beforeCount,
      after_scanner_count: afterCount,
      before_scanner_sn: beforeSerialNumbers.length > 0 ? beforeSerialNumbers: null,
      after_scanner_sn: afterSerialNumbers.length > 0 ? afterSerialNumbers: null,
      hw_type: hwType,

      type: scannerType,
      ui_id: uiId,
      inference_ip: inferIp,
      std_volume: stdVolume,
      off_time: offTime,
      edge_ai: edgeAI,
    }
    console.log(scannerRecordInfo)

    const fetchData = async () => {
      try {
        await axiosInstance.post("/api/scanner", scannerRecordInfo)
        .then(response => {
          console.log(response)
          showModal(true, "Register Success", response.data.message);
        })
        .catch(e =>{
          showModal(false, "Register Fail",  e.response.data.detail);
        });
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }

  return (
    <div>
      <div className="column-layout">
        <div className="column">
          <ScannerInfo 
          />
        </div>
        <div className="column">
          <ScannerSetting 
          />
        </div>
      </div>
      <Button 
        onClick={onSendScannerRecord} 
        disabled={
          hwType === "VS" ? dpmtId===undefined || hwType===undefined || scannerType===undefined || afterCount+beforeCount<=0 || afterCount !== afterSerialNumbers.length || beforeCount !== beforeSerialNumbers.length || uiId === undefined || (scannerType !== "class" && scannerType !== "id" && uiId as number <= 0):
          hwType !== "DD" ? dpmtId===undefined || hwType===undefined || scannerType===undefined || afterCount+beforeCount<=0 || uiId === undefined || (scannerType !== "class" && scannerType !== "id" && uiId as number <= 0):
          dpmtId===undefined || hwType===undefined || afterCount+beforeCount<=0 
        }
      >
        send
      </Button>
    </div>
  );
};

export default ScannerPage;
