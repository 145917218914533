import { Form, Radio } from "antd";

export interface BAInfo{
  ba: string
}

function BA(props: any) {
  return (
    <>
      <h2>BA</h2>
      <Form>
        <Form.Item label="Before/After">
          <Radio.Group value={props.info.ba} onChange={(e) => props.setInfo({...props.info, ba: e.target.value})}>
            <Radio.Button value="B">배식</Radio.Button>
            <Radio.Button value="A">퇴식</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    </>
  );
};

export default BA;
